import ENVIRONMENT from 'config/environment';
import { Button } from 'antd';
import { useDispatch } from 'react-redux';
import { logout } from 'store/container/auth/auth-slice';
import styles from '../Login/login.module.less';

const AuthClient = () => {
  const dispatch = useDispatch();

  return (
    <div className={styles.container__login}>
      <div className={styles.content}>
        <div className={styles.container__message}>
          <h2 className={styles.container__message__title}>Important update!</h2>
          <p className={styles.container__message__text}>
            We have moved! Visit{' '}
            <a href={ENVIRONMENT.CLIENT_URL} target="_blank" rel="noreferrer">
              {ENVIRONMENT.CLIENT_URL}
            </a>{' '}
            and explore our newly designed dashboard, which offers a more modern and user-friendly experience than ever before.
          </p>

          <Button
            style={{
              width: 'max-content',
            }}
            href={ENVIRONMENT.CLIENT_URL}
            target="_blank"
            size="large"
            type="primary"
            block
          >
            Take me there
          </Button>

          <p className={styles.container__message__tip}>
            Make sure to bookmark this link{' '}
            <a target="_blank" href={ENVIRONMENT.CLIENT_URL} rel="noreferrer">
              {ENVIRONMENT.CLIENT_URL}
            </a>{' '}
            for easy access to lionshare and our valuable resources.
          </p>

          <Button size="small" type="primary" onClick={() => dispatch(logout())}>
            Logout
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AuthClient;
