/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-sort-props */
/* eslint-disable import/no-cycle */
import { FC } from 'react';
import { Form } from 'antd';
import { EditableContext } from 'components/CustomTable';

interface EditableRowProps {
  index: number;
}

const EditableRow: FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();

  return (
    <Form component={false} form={form}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

export default EditableRow;
