/* eslint-disable max-len */
import { FC, useState } from 'react';
import { Button, Form, Modal, Row, Typography } from 'antd';
import apiRoutes from 'config/apiRoute';
import asyncErrorHandler from 'utils/asyncErrorHandler';
import apiRequests from 'utils/api';
import { useHistory } from 'react-router-dom';
import appRoutes from 'config/appRoutes';
import moment from 'moment';
import SelectSearchFrontend from 'components/SelectSearchFrontend';
import { getCustomerSelect } from 'modules/customer/store/customerSelect-slice';

interface AddProjectModalProps {
  visible: boolean;
  onClose: any;
}

const { Title } = Typography;
const AddProjectModal: FC<AddProjectModalProps> = ({ visible, onClose }) => {
  const [form] = Form.useForm();

  const [state, setState] = useState({ saving: false });
  const { saving } = state;

  const history = useHistory();

  const createProject = async () => {
    try {
      setState((prevState) => ({ ...prevState, saving: true }));
      const values = await form.validateFields();
      const res = await apiRequests.post(apiRoutes.PROJECTS, {
        ...values,
        status: 'edit',
        start_date: moment().format('YYYY-MM-DD'),
      });
      history.push(`${appRoutes.PROJECTS}/${res?.data?.data?.uuid}`);
      if (onClose) {
        onClose();
      }

      setState((prevState) => ({ ...prevState, saving: false }));
    } catch (error) {
      asyncErrorHandler(error);
      setState((prevState) => ({ ...prevState, saving: false }));
    }
  };

  return (
    <Modal
      confirmLoading={saving}
      closeIcon={<div onClick={onClose} />}
      footer={null}
      onCancel={onClose}
      title={
        <Row justify="space-between">
          <Title className="mb-0 primary" level={3}>
            Create a new project
          </Title>
          <Button loading={saving} onClick={() => createProject()} type="primary">
            Next
          </Button>
        </Row>
      }
      visible={visible}
    >
      <Form colon={false} form={form} labelCol={{ span: 6 }}>
        <SelectSearchFrontend
          autoFocus
          allowClear
          label="Client name"
          name="customer_id"
          rules={[
            {
              required: true,
              message: 'This field is required',
            },
          ]}
          placeholder="Select a customer"
          searchKeys={['phone', 'business_name', 'status', 'email', 'website', 'abbreviation']}
          optionLabel="business_name"
          storeKeySelector="customerSelect"
          storeAction={getCustomerSelect}
        />
      </Form>
    </Modal>
  );
};

export default AddProjectModal;
