import { FC } from 'react';
import { Avatar } from 'antd';
import { AvatarSize } from 'antd/lib/avatar/SizeContext';
import { generateHexColor, getAvatar } from 'utils/string.utils';

interface UserAvatarProps {
  user?: { name: string; avatar?: null | string } | undefined | null;
  size?: AvatarSize | undefined;
}

const UserAvatar: FC<UserAvatarProps> = ({ user, size }) => {
  const avatarObj = getAvatar(user);

  return (
    <Avatar
      shape="square"
      size={size}
      src={avatarObj?.type === 'image' ? avatarObj.value : undefined}
      style={{ background: avatarObj ? generateHexColor(avatarObj.value) : undefined }}
    >
      {avatarObj?.type === 'text' ? avatarObj?.value : undefined}
    </Avatar>
  );
};

export default UserAvatar;
