import { Badge, Collapse } from 'antd';
import { FC } from 'react';
import SubTasksList from '../SubTasksList';

const { Panel } = Collapse;

interface SubTasksCollapseProps {
  task: Record<string, any>;
  onTaskUpdate: () => Promise<void>;
}

const SubTasksCollapse: FC<SubTasksCollapseProps> = ({ task, onTaskUpdate }) => {
  return (
    <Collapse className="task-modal-collapse no-padding mb-m">
      <Panel
        header="Subtasks"
        key="sub_task"
        extra={<Badge count={task.subtasks?.length ?? 0} className={!task.subtasks?.length ? 'ant-badge-gray' : ''} showZero />}
      >
        <SubTasksList task={task} onTaskUpdate={onTaskUpdate} />
      </Panel>
    </Collapse>
  );
};

export default SubTasksCollapse;
