/* eslint-disable no-nested-ternary */
import MinSpan from 'components/MinSpan';

const useColumns = (columns: any[], sort: any, editable: boolean, onSave?: any) => {
  columns = columns.map((el) => ({
    ...el,
    render:
      el?.minSpace || !el?.render
        ? (value: any, row: any) => (
            <MinSpan
              text={el.minSpaceText ? el.minSpaceText(value, row) : value}
              count={typeof el.title?.length === 'number' ? el.title.length * 2 + 3 : 10}
            >
              {el.render ? el.render(value, row) : value}
            </MinSpan>
          )
        : el?.render,
  }));

  if (editable) {
    columns = columns.map((el) => {
      return {
        ...el,
        onCell: (record: any) => ({
          record,
          editable: el.editable,
          dataIndex: el.dataIndex,
          title: el.title,
          handleSave: onSave,
        }),
      };
    });
  }
  if (sort) {
    return columns.map((el) =>
      (Array.isArray(el?.dataIndex) ? el?.dataIndex?.join('.') : el?.dataIndex) === sort?.column || el?.key === sort?.column
        ? { ...el, defaultSortOrder: sort?.order }
        : el,
    );
  }
  return columns;
};

export default useColumns;
