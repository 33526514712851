/* eslint-disable no-nested-ternary */
import { FC, useMemo, useState } from 'react';
import { ContainerOutlined, DownOutlined, PlusOutlined, UpOutlined } from '@ant-design/icons';
import { Alert, Button, Dropdown, Form, Input, Menu, Row, Space, Typography, Col, Collapse, Badge } from 'antd';
import AssigneesFilter from '../AssigneesFilter';
import ContentCategoryFilter from '../ContentCategoryFilter';
import FilterMenu from '../FilterMenu';
import CreatedByFilter from '../CreatedByFilter';
import ActionButtons from '../ActionButtons';

interface ActionRowProps {
  hideActionBar: any;
  showAssigneesFilter: boolean;
  filterLabel?: string;
  filtersOptions: any;
  search: any;
  handleSearch: any;
  filter: any;
  handleSetFilter: any;
  defaultFilter: any;
  buttons: any;
  loading: any;
  selectedRowKeys: any;
  showResetFilter: any;
  multipleFilters: any;
  handleClearFilters: any;
  bulkLoading: any;
  _bulkLoading: any;
  toggleExportModal: any;
  triggerEffect: any;
  hideBulkDelete: any;
  bulkOpsOptions: any;
  handleStatus: any;
  hideBulkStatus: any;
  selectAllRows: any;
  onReload: any;
  onSelectAll: any;
  selectAllCurrentRows: any;
  pagination: any;
  data: any;
  hideSearch: any;
  hideDefaultBulkOptions: any;
  showCategoryFilter: any;
  actionFilters: any;
  advancedFiltersStart: any;
  advancedFilters: any;
  advancedFiltersCount?: number;
  filterRender: any;
  onResetFilters: any;
  assigneesForm: any;
  countOnlyChildren: any;
  hideSelectAllFields: any;
  hideExportButton: boolean;
  hideReloadButton?: boolean;
  collapseAdvancedFilters?: boolean;
}

const { Search } = Input;
const { Text } = Typography;

const ActionRow: FC<ActionRowProps> = ({
  hideActionBar,
  showAssigneesFilter,
  filterLabel,
  filtersOptions,
  actionFilters,
  advancedFilters,
  advancedFiltersStart,
  advancedFiltersCount = 0,
  filterRender,
  search,
  handleSearch,
  filter,
  handleSetFilter,
  defaultFilter,
  buttons,
  loading,
  selectedRowKeys,
  _bulkLoading,
  bulkLoading,
  handleClearFilters,
  multipleFilters,
  showResetFilter,
  toggleExportModal,
  triggerEffect,
  bulkOpsOptions,
  handleStatus,
  hideBulkDelete,
  hideSelectAllFields,
  hideBulkStatus,
  selectAllRows,
  onSelectAll,
  pagination,
  selectAllCurrentRows,
  data,
  hideSearch,
  hideDefaultBulkOptions,
  onResetFilters,
  showCategoryFilter,
  onReload,
  assigneesForm,
  countOnlyChildren,
  hideExportButton,
  hideReloadButton,
  collapseAdvancedFilters = false,
}) => {
  const getTotalLength = () => {
    let len = 0;
    data?.forEach((el: any) => {
      len += countOnlyChildren ? 0 : 1;
      if (el?.children && Array.isArray(el?.children)) {
        len += el?.children?.length;
      }
    });
    return len;
  };
  const selectedChildren = selectedRowKeys?.filter((el: any) => el?.includes('---'));

  const [collapsedAdvanced, setCollapsedAdvanced] = useState(false);

  const totalFieldsLength = getTotalLength();

  const statusOptions = [
    {
      label: 'Active',
      onClick: () => handleStatus('active'),
    },
    {
      label: 'Inactive',
      onClick: () => handleStatus('inactive'),
    },
  ];

  let menuOptions = [...bulkOpsOptions];

  if (!hideBulkDelete) {
    menuOptions = [
      ...menuOptions,
      // ...deleteOptions,
    ];
  }

  if (!hideBulkStatus) {
    menuOptions = [...menuOptions, ...(hideDefaultBulkOptions ? [] : statusOptions)];
  }

  const menu = (
    <Menu>
      {menuOptions.map((el, i) => (
        <Menu.Item
          key={i}
          onClick={() => {
            if (el.onClick) {
              el.onClick(handleStatus, selectedRowKeys, selectAllRows);
            }
          }}
        >
          {el.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  const wrapAdvancedFilters = (
    <>
      {advancedFiltersStart}

      {filtersOptions?.length > 0 && (
        <Col xs={24} md={showAssigneesFilter ? 6 : 12} lg={5}>
          <FilterMenu
            label={filterLabel}
            onResetFilters={onResetFilters}
            assigneesForm={assigneesForm}
            filterRender={filterRender}
            defaultFilter={defaultFilter}
            showResetFilter={showResetFilter}
            filtersOptions={filtersOptions}
            loading={loading}
            filter={filter}
            multipleFilters={multipleFilters}
            handleClearFilters={handleClearFilters}
            handleSetFilter={handleSetFilter}
          />
        </Col>
      )}

      {menuOptions.length > 0 && (
        <Col xs={24} md={12} lg={5}>
          <Dropdown disabled={selectedRowKeys?.length === 0 || bulkLoading || _bulkLoading || loading} overlay={menu}>
            <Button size="large" block className="btn-outline-primary" icon={<ContainerOutlined />}>
              Bulk Edit
            </Button>
          </Dropdown>
        </Col>
      )}

      {buttons.map((el: any, i: any) =>
        el.render ? (
          el.render(selectedRowKeys, i)
        ) : (
          <Col xs={24} md={12} lg={4} key={i}>
            <Button {...(el?.props ?? {})} block size="large" onClick={el.onClick} type="primary">
              <PlusOutlined />
              {el.label}
            </Button>
          </Col>
        ),
      )}

      {advancedFilters}

      {!hideReloadButton && !hideExportButton && (
        <Col>
          <ActionButtons
            showExportButton={!hideExportButton}
            showReloadButton={!hideReloadButton}
            loadingExportButton={bulkLoading || _bulkLoading}
            loadingReloadButton={loading}
            onExport={toggleExportModal}
            onReload={onReload ?? triggerEffect}
          />
        </Col>
      )}
    </>
  );

  const advancedFilterInternCount = useMemo(() => {
    if (!collapseAdvancedFilters) return 0;

    const filterKeys: string[] = [];

    filtersOptions
      .filter((x: any) => defaultFilter.find((y: any) => y.filterBy === x.filterBy && y.value === x.value))
      .forEach((x: any) => {
        if (!filterKeys.includes(x.filterBy) && !filter.find((y: any) => y.filterBy === x.filterBy && y.value === x.value)) {
          filterKeys.push(x.filterBy);
        }
      });

    filter.forEach((x: any) => {
      if (
        !['related_users.uuid', 'creator.uuid', 'category.uuid'].includes(x.filterBy) &&
        !filterKeys.includes(x.filterBy) &&
        !defaultFilter.find((y: any) => y.filterBy === x.filterBy && y.value === x.value)
      ) {
        filterKeys.push(x.filterBy);
      }
    });

    return filterKeys.length;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, collapseAdvancedFilters]);

  return (
    <div style={{ width: '100%' }}>
      {!hideActionBar ? (
        <>
          <Row gutter={[16, 8]} align="middle">
            {!hideSearch && (
              <Col flex="auto" className="w-full xl-w-auto">
                <Form form={assigneesForm}>
                  <Form.Item className="mb-0" name="search">
                    <Search
                      size="large"
                      allowClear
                      className="custom-searchbar"
                      defaultValue={search}
                      onSearch={handleSearch}
                      placeholder="Search"
                      style={{ width: '100%', minWidth: '120px' }}
                    />
                  </Form.Item>
                </Form>
              </Col>
            )}

            {showAssigneesFilter && (
              <Col xs={24} md={12} xl={8} xxl={8}>
                <AssigneesFilter form={assigneesForm} filter={filter} handleSetFilter={handleSetFilter} />
              </Col>
            )}

            {showAssigneesFilter && (
              <Col xs={24} md={12} xl={8} xxl={8}>
                <CreatedByFilter form={assigneesForm} filter={filter} handleSetFilter={handleSetFilter} />
              </Col>
            )}

            {showCategoryFilter && (
              <Col xs={24} lg={12} xl={8} xxl={4}>
                <ContentCategoryFilter filter={filter} handleSetFilter={handleSetFilter} />
              </Col>
            )}

            {actionFilters}

            {collapseAdvancedFilters ? (
              <Col>
                <Button
                  type="link"
                  style={{ paddingLeft: 0, paddingRight: 0, marginRight: 8 }}
                  onClick={() => setCollapsedAdvanced(!collapsedAdvanced)}
                >
                  {collapsedAdvanced ? 'Collapse' : 'Expand'}
                  {collapsedAdvanced ? <UpOutlined /> : <DownOutlined />}
                </Button>
                <Badge count={advancedFilterInternCount + advancedFiltersCount} />
              </Col>
            ) : (
              wrapAdvancedFilters
            )}
          </Row>

          {collapseAdvancedFilters && (
            <Collapse bordered={false} activeKey={collapsedAdvanced ? '1' : ''} className="headless" style={{ paddingTop: 4 }}>
              <Collapse.Panel header="" key="1">
                <Row gutter={[16, 8]} align="middle">
                  {wrapAdvancedFilters}
                </Row>
              </Collapse.Panel>
            </Collapse>
          )}
        </>
      ) : null}
      {selectedRowKeys?.length > 0 && (
        <Alert
          style={{ margin: '8px 0' }}
          message={
            <Space>
              <Text>
                You have selected{' '}
                <Text strong>
                  {countOnlyChildren ? selectedChildren?.length : selectAllRows ? pagination?.total : selectedRowKeys?.length}
                </Text>{' '}
                records
              </Text>
              {selectAllCurrentRows && selectedRowKeys?.length >= data?.length && !hideSelectAllFields ? (
                <Button className="primary" onClick={() => onSelectAll(!selectAllRows)} size="small" type="text">
                  {selectAllRows
                    ? 'Select only current page records'
                    : `Select all ${pagination?.total ?? totalFieldsLength ?? data?.length} records`}
                </Button>
              ) : (
                <></>
              )}
            </Space>
          }
          type="warning"
        />
      )}
    </div>
  );
};

export default ActionRow;
