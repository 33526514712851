/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC } from 'react';

import { Button, Checkbox, Modal, Space, Typography } from 'antd';

interface ExportModalProps {
  handleParseToCSV: any;
  toggleExportModal: any;
  handleParseToXLSL: any;
  exportModalFormat: any;
  selectedRowKeys: any;
  exportModal: boolean;
  setState: any;
  exporting: boolean;
}

const { Title } = Typography;
const ExportModal: FC<ExportModalProps> = ({
  toggleExportModal,
  handleParseToXLSL,
  exportModalFormat,
  exportModal,
  selectedRowKeys,
  setState,
  exporting,
  handleParseToCSV,
}) => (
  <Modal
    footer={
      <Space>
        <Button danger onClick={toggleExportModal}>
          Cancel
        </Button>
        <Button disabled={!selectedRowKeys.length} loading={exporting} onClick={() => handleParseToCSV(false)}>
          Export only Selected Rows
        </Button>
        <Button loading={exporting} onClick={() => handleParseToCSV(true)}>
          Export all Rows
        </Button>
      </Space>
    }
    onCancel={toggleExportModal}
    visible={exportModal}
  >
    <Space direction="vertical">
      <Title className="primary mb-0" level={5}>
        Select the export data type
      </Title>
      {/* <Space>
        <div style={{ width: 60 }}>CSV</div>
        <Checkbox
          checked={exportModalFormat === 'CSV'}
          onChange={(val) => setState((prevState: any) => ({ ...prevState, exportModalFormat: val ? 'CSV' : null }))}
        />
      </Space> */}
      {/* <Space>
        <div style={{ width: 60 }}>Excel</div>
        <Checkbox
          checked={exportModalFormat === 'Excel'}
          onChange={(val) => setState((prevState: any) => ({ ...prevState, exportModalFormat: val ? 'Excel' : null }))}
        />
      </Space> */}
    </Space>
  </Modal>
);

export default ExportModal;
