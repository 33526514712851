import { FC, useMemo } from 'react';
import { Avatar, Tooltip } from 'antd';
import UserAvatar from 'components/UserAvatar';

interface UserAvatarsProps {
  users?: any[];
  filterType?: 'responsible' | 'follower';
  maxCount?: number;
}

const UserAvatars: FC<UserAvatarsProps> = ({ users, filterType, maxCount = 3 }) => {
  const list = useMemo(() => {
    return filterType ? users?.filter((item) => item.pivot?.type === filterType) : users;
  }, [filterType, users]);

  return (
    <Avatar.Group style={{ display: 'inline-block' }} maxCount={maxCount}>
      {list?.map((item) => (
        <Tooltip key={item.uuid} title={item.name} placement="top">
          <UserAvatar user={item} />
        </Tooltip>
      ))}
    </Avatar.Group>
  );
};

export default UserAvatars;
