import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Form } from 'antd';
import { StopOutlined } from '@ant-design/icons';
import { useStopwatch, useTimer } from 'react-timer-hook';
import { checkIsAdmin } from 'utils/auth';
import { getDateDifferenceInMinutes, parseDateNumberToTimeFormat } from 'utils/string.utils';

interface ApprovalCountDownProps {
  approval_target?: any;
  onCancel?: any;
}

const ApprovalCountDown: FC<ApprovalCountDownProps> = ({ approval_target, onCancel }) => {
  const user = useSelector((store: any) => store.auth.user);
  const target = new Date(approval_target);

  const offsetTimestamp = new Date();
  offsetTimestamp.setMinutes(offsetTimestamp.getMinutes() + getDateDifferenceInMinutes(target.toString()));

  const timer = useTimer({
    expiryTimestamp: target,
    autoStart: true,
  });
  const time = useStopwatch({
    offsetTimestamp,
    autoStart: true,
  });

  const isExceeded = target < new Date(Date.now());
  const isAdmin = checkIsAdmin(user);

  return (
    <Form.Item className="mb-0" label="Approval Time">
      <div
        style={{
          color: '#FF0000',
          fontWeight: 700,
          fontSize: isExceeded ? '12px' : '14px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginRight: 6,
        }}
      >
        <span>
          {isExceeded
            ? `You are late ${time?.days} day(s)`
            : `${parseDateNumberToTimeFormat(timer.days, timer.hours, timer.minutes)} Left`}
        </span>
        {isAdmin && <StopOutlined onClick={onCancel} className="pointer" />}
      </div>
    </Form.Item>
  );
};

export default ApprovalCountDown;
