import { FC } from 'react';
import { Tag } from 'antd';
import { convertSnakeCaseToTitleCase, getStatusColor } from 'utils/string.utils';

interface StatusTagProps {
  value?: string;
  size?: 'small' | 'default';
  onClick?: any;
}

const StatusTag: FC<StatusTagProps> = ({ value = '', size, onClick }) => {
  const isSmall = size === 'small';

  return (
    <Tag style={{ marginRight: 0, lineHeight: isSmall ? '16px' : undefined }} onClick={onClick} color={getStatusColor(value)}>
      {convertSnakeCaseToTitleCase(value === 'seed' ? 'master' : value)}
    </Tag>
  );
};

export default StatusTag;
