/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { Button, Dropdown, Row } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { RootState } from 'store';
import TimerDropdown from 'components/Timer/TimerDropdown';
import styles from './TasksDropdown.module.less';

interface TasksDropdownProps {
  onEmpty?: any;
}

const TasksDropdown: FC<TasksDropdownProps> = ({ onEmpty }) => {
  const { data, loading } = useSelector((store: RootState) => store.activeLogs);
  const runningLogs = data?.filter((el: any) => el.status === 'running' && data?.length > 1);

  useEffect(() => {
    if (!onEmpty) return;

    if (!(data && data?.length > 0)) {
      onEmpty(true);
    } else {
      onEmpty(false);
    }
  }, [data]);

  if (!(data && data?.length > 0)) {
    return null;
  }

  return (
    <Dropdown
      trigger={[isMobile ? 'click' : 'hover']}
      placement="bottomRight"
      overlay={
        <div className={styles.dropdown}>
          <div className={`${styles.wrapper} ${loading ? styles.loading : ''}`} onClick={(event) => event.stopPropagation()}>
            {runningLogs && runningLogs.length > 0 && (
              <div className={styles.head}>
                {runningLogs.map((el: any) =>
                  el.status !== 'running' ? null : (
                    <Row className={styles.item} justify="space-between" key={el.uuid}>
                      <Link className={styles.title} to={`?task_uuid=${el.task?.uuid}`}>
                        {el.task?.title}
                      </Link>

                      <TimerDropdown timerContent={el} />
                    </Row>
                  ),
                )}
              </div>
            )}
            <div className={styles.body} onClick={(event) => event.stopPropagation()}>
              {data?.map((el: any) =>
                el.status === 'running' && data?.length > 1 ? null : (
                  <Row className={styles.item} justify="space-between" key={el.uuid}>
                    <Link className={styles.title} to={`?task_uuid=${el.task?.uuid}`}>
                      {el.task?.title}
                    </Link>
                    <TimerDropdown timerContent={el} />
                  </Row>
                ),
              )}
            </div>
          </div>
        </div>
      }
    >
      <Button type="primary" shape="circle" icon={<DownOutlined style={{ fontSize: 14 }} />} />
    </Dropdown>
  );
};

export default TasksDropdown;
