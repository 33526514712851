/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const GET_FORMS = 'GET_FORMS';
export const GET_FORMS_PENDING = 'GET_FORMS_PENDING';
export const GET_FORMS_FULLFILLED = 'GET_FORMS_FULLFILLED';
export const GET_FORMS_REJECTED = 'GET_FORMS_REJECTED';

export const getForms = (payload = {}): any => ({
  type: GET_FORMS,
  payload,
});
export const getFormsPending = (): any => ({
  type: GET_FORMS_PENDING,
});

export const getFormsFullfilled = (data: any): any => ({
  type: GET_FORMS_FULLFILLED,
  data,
});

export const getFormsRejected = (error: any) => ({
  type: GET_FORMS_REJECTED,
  error,
});
