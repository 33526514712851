/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import { FC, useEffect, useState } from 'react';
import { Badge, Button, Checkbox, Dropdown, Menu, Space } from 'antd';
import { CiFilter } from 'react-icons/ci';
import { arrayContains } from 'utils/array.utils';
import styles from './FilterMenu.module.less';

export interface FiltersOption {
  label: string;
  filterBy: string;
  value: string;
}

interface FilterMenuProps {
  label?: string;
  handleSetFilter: any;
  handleClearFilters: any;
  multipleFilters?: boolean;
  filter: any;
  filtersOptions?: FiltersOption[];
  showResetFilter?: boolean;
  defaultFilter?: any;
  loading?: boolean;
  filterRender?: any;
  assigneesForm?: any;
  onResetFilters?: any;
}

const FilterMenu: FC<FilterMenuProps> = ({
  label = 'Filters',
  handleSetFilter,
  handleClearFilters,
  multipleFilters,
  filter,
  filtersOptions,
  showResetFilter,
  defaultFilter,
  filterRender,
  loading,
  assigneesForm,
  onResetFilters,
}) => {
  const [visible, setVisible] = useState(false);
  const [current, setCurrent] = useState<any[]>([]);

  const getBadgeCount = () => {
    if (!filtersOptions) {
      return 0;
    }

    if (Array.isArray(filter)) {
      return filter.filter((el) => filtersOptions.find((x) => x.label === el.label)).length;
    }

    return filtersOptions.find((x) => x.value === filter?.value) ? 1 : 0;
  };

  useEffect(() => {
    if (visible) {
      setCurrent(filter ? (Array.isArray(filter) ? [...filter] : [filter]) : []);
    } else {
      setTimeout(() => {
        setCurrent([]);
      }, 500);
    }
  }, [visible]);

  const filtersMenu = multipleFilters ? (
    <Menu style={{ zIndex: 100 }} selectedKeys={[filter?.label]}>
      {filtersOptions?.map((el) => (
        <Menu.Item
          key={el.label}
          onClick={() =>
            setCurrent(
              arrayContains(current, 'label', el?.label)
                ? [...current?.filter((_el) => _el.label !== el.label)]
                : [...current, el],
            )
          }
        >
          <Checkbox checked={arrayContains(current, 'label', el?.label)} /> {filterRender ? filterRender(el.label) : el.label}
        </Menu.Item>
      ))}
      {showResetFilter ? (
        <Menu.Item key="reset-filters">
          <Space>
            <Button
              size="small"
              onClick={() => {
                handleSetFilter(defaultFilter);
                setVisible(false);
                if (onResetFilters) {
                  onResetFilters(assigneesForm);
                }
              }}
            >
              Reset
            </Button>
            <Button
              onClick={() => {
                handleSetFilter(current);
                setVisible(false);
              }}
              type="primary"
              size="small"
            >
              Ok
            </Button>
          </Space>
        </Menu.Item>
      ) : (
        <Menu.Item key="clear-filters" onClick={handleClearFilters}>
          Clear filters
        </Menu.Item>
      )}
    </Menu>
  ) : (
    <Menu style={{ zIndex: 100 }} selectedKeys={[filter?.label]}>
      {filtersOptions?.map((el) => (
        <Menu.Item key={el.label} onClick={() => handleSetFilter(el)}>
          {el.label}
        </Menu.Item>
      ))}
      <Menu.Item key="clear-filters" onClick={handleClearFilters}>
        Clear filters
      </Menu.Item>
    </Menu>
  );
  return (
    <>
      {visible && <div onClick={() => setVisible(false)} className={styles.overlay} />}
      <Dropdown visible={visible} disabled={loading} overlay={filtersMenu}>
        <Button
          className="btn-outline-primary ant-btn-icon-center"
          size="large"
          icon={<CiFilter />}
          block
          onClick={() => setVisible(true)}
        >
          <span style={{ verticalAlign: 'middle' }}>{label}</span>

          <Badge count={getBadgeCount()} style={{ marginLeft: 8, backgroundColor: '#8ea7e8', color: 'black' }} />
        </Button>
      </Dropdown>
    </>
  );
};

export default FilterMenu;
