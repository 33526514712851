import { createAppTableSaga, createPersist } from 'components/AppTable/store';
import storeTableTaskFactory from 'components/TasksListTabs/storeTableTaskFactory';

export const projectTaskSlice = storeTableTaskFactory({
  name: 'tableProjectTasks' as const,
  onlyTable: false,
  initialState: {
    fixedParams: { ignore_project: true },
  },
});

export const projectTasksSaga = createAppTableSaga(projectTaskSlice);

export const projectTasksReducer = createPersist(projectTaskSlice, ['pagination', 'tabKey']);
