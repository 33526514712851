import { FC } from 'react';

import { Modal, Typography } from 'antd';

const { Text } = Typography;

interface BulkDeleteModalProps {
  visible: boolean;
  onClose: any;
  count: any;
  onOk: any;
  loading: boolean;
}

const BulkDeleteModal: FC<BulkDeleteModalProps> = ({ count, visible, onClose, onOk, loading }) => (
  <Modal confirmLoading={loading} okText="Delete" okType="danger" onCancel={onClose} onOk={onOk} visible={visible}>
    <Text strong>Do you want to delete {count} records?</Text>
  </Modal>
);

export default BulkDeleteModal;
