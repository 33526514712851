import { FC } from 'react';
import { isMobile } from 'react-device-detect';
import { MoreOutlined } from '@ant-design/icons';
import { Dropdown, Menu, MenuItemProps, Tooltip, Typography } from 'antd';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { checkExternalUrl } from 'utils/string.utils';
import styles from './index.module.less';

function getRelativeUrl(urlString: string) {
  const url = new URL(urlString);

  if (url.pathname.endsWith('/tasks')) {
    const taskUuid = url.searchParams.get('uuid') ?? url.searchParams.get('task_uuid');

    if (taskUuid) {
      const commentUuid = url.searchParams.get('target_comment_id') ?? url.searchParams.get('task_comment_uuid');

      return `?task_uuid=${taskUuid}${commentUuid ? `&task_comment_uuid=${commentUuid}` : ''}`;
    }
  }

  return url.pathname + url.search;
}

export type Notification = {
  id: string;
  number: number;
  data: {
    title: string;
    sub_title: string | null | undefined;
    content: string;
    action_url: string;
    resource_id: string;
    has_mention?: boolean;
  };
  notifiable_id: number;
  notifiable_type: string;
  read_at: string | null;
  created_at: string;
  updated_at: string;
};

interface CardDropdownNotificationProps {
  notification: Notification;
  onMarkAsRead: (item: Notification) => void;
  onMarkAsUnread: (item: Notification) => void;
  onDelete: (item: Notification) => void;
}

const { Text } = Typography;

const DropdownCard: FC<CardDropdownNotificationProps> = ({ notification, onMarkAsRead, onMarkAsUnread, onDelete }) => {
  const { data, read_at: readAt, created_at: createdAt } = notification;
  const { title, sub_title: subTitle, content, action_url: actionUrl } = data;

  const markAsReadHandler: MenuItemProps['onClick'] = (info) => {
    info.domEvent.stopPropagation();
    onMarkAsRead(notification);
  };

  const markAsUnreadHandler: MenuItemProps['onClick'] = (info) => {
    info.domEvent.stopPropagation();
    onMarkAsUnread(notification);
  };

  const deleteHandler: MenuItemProps['onClick'] = (info) => {
    info.domEvent.stopPropagation();
    onDelete(notification);
  };

  const LinkContent = (
    <>
      <div>
        <p className={styles.dropdownCardTitle}>{title}</p>
        {subTitle ? <Text type="secondary">{subTitle}</Text> : undefined}
        <article>{content}</article>
      </div>

      <Tooltip title={dayjs(createdAt).format('MMMM DD, YYYY HH:mm A')}>{dayjs(createdAt).fromNow(true)}</Tooltip>
    </>
  );

  return (
    <div className={`${styles.dropdownCard} ${!readAt ? styles.dropdownCardUnRead : ''}`}>
      {checkExternalUrl(actionUrl) ? (
        <a
          href={actionUrl}
          target="_blank"
          rel="noreferrer"
          style={{ color: 'rgb(58, 66, 77)' }}
          onClick={!readAt ? () => onMarkAsRead(notification) : undefined}
        >
          {LinkContent}
        </a>
      ) : (
        <Link
          to={getRelativeUrl(actionUrl)}
          style={{ color: 'rgb(58, 66, 77)' }}
          onClick={!readAt ? () => onMarkAsRead(notification) : undefined}
        >
          {LinkContent}
        </Link>
      )}

      <Dropdown
        placement="bottomRight"
        trigger={[isMobile ? 'click' : 'hover']}
        overlay={
          <Menu>
            {!readAt ? (
              <Menu.Item key="0" onClick={markAsReadHandler}>
                Mark as read
              </Menu.Item>
            ) : (
              <Menu.Item key="0" onClick={markAsUnreadHandler}>
                Mark as unread
              </Menu.Item>
            )}
            <Menu.Item key="1" onClick={deleteHandler}>
              Delete
            </Menu.Item>
          </Menu>
        }
      >
        <button type="button" className="default-button" onClick={(event) => event.stopPropagation()}>
          <MoreOutlined style={{ fontSize: 24 }} />
        </button>
      </Dropdown>
    </div>
  );
};

export default DropdownCard;
