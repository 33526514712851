import { createSlice } from '@reduxjs/toolkit';

export const rolesSlice = createSlice({
  name: 'roles',
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {
    getRoles: () => {},
    setRolesLoading: (state) => ({ ...state, loading: true }),
    setRolesData: (state, action) => ({ ...state, loading: false, data: action.payload }),
    setRolesError: (state, action) => ({ ...state, loading: false, error: action.payload }),
  },
});

export const { setRolesData, setRolesError, setRolesLoading, getRoles } = rolesSlice.actions;

export default rolesSlice.reducer;
