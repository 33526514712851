import { put, takeLatest } from 'redux-saga/effects';
import { createAppTableSaga, createAppTableStore } from 'components/AppTable/store';

export const projectTasksTimelogSlice = createAppTableStore({
  name: 'tableProjectTasksTimelog' as const,
  url: '',
  initialState: {
    params: {
      sort: { column: 'date', order: 'descend' },
    },
    paramFilterFields: {
      date_start: 'date_from',
      date_end: 'date_to',
    },
    pagination: null,
    serverControl: false,
    totalTime: null as null | number,
  },
  reducers: {
    setTotalTime: (state, { payload }: { payload: number; type: string }) => {
      return {
        ...state,
        totalTime: payload,
      };
    },
  },
});

function* handleApiResponseSagaListener({ payload }: { payload: any }) {
  const totalTime = payload?.data?.reduce((acc: number, item: any) => acc + item.time, 0);

  yield put(projectTasksTimelogSlice.actions.setTotalTime(totalTime));
}

export const projectTasksTimelogSaga = createAppTableSaga(projectTasksTimelogSlice, function* customGenerator() {
  yield takeLatest(projectTasksTimelogSlice.actions.handleApiResponse, handleApiResponseSagaListener);
});

export const projectTasksTimelogReducer = projectTasksTimelogSlice.reducer;
