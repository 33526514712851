import { AxiosResponse } from 'axios';
import apiRoutes from 'config/apiRoute';
import { call, put, StrictEffect, takeEvery } from 'redux-saga/effects';
import api from 'utils/api';
import asyncErrorHandler from 'utils/asyncErrorHandler';

import { getContent, setContentData, setContentError, setContentLoading } from './content-slice';

const { get } = api;

function* getContentSagaListener(action: any) {
  try {
    yield put(setContentLoading());
    const res: AxiosResponse = yield call(get, apiRoutes.POSTS, action.payload);
    yield put(setContentData(res.data));
  } catch (error) {
    yield put(setContentError(error));
    asyncErrorHandler(error);
  }
}

function* contactsSaga(): Generator<StrictEffect> {
  yield takeEvery(getContent.type, getContentSagaListener);
}

export default contactsSaga;
