import { createSlice } from '@reduxjs/toolkit';

export const customersSlice = createSlice({
  name: 'customers',
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {
    getCustomers: () => {},
    setCustomersLoading: (state) => ({ ...state, loading: true }),
    setCustomersData: (state, action) => ({ ...state, loading: false, data: action.payload }),
    setCustomersError: (state, action) => ({ ...state, loading: false, error: action.payload }),
  },
});

export const { setCustomersData, setCustomersError, setCustomersLoading, getCustomers } = customersSlice.actions;

export default customersSlice.reducer;
