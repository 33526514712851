import { FC } from 'react';
import { Row, SelectProps } from 'antd';
import type { FormItemProps } from 'antd/lib/form/FormItem';
import type { SizeType } from 'antd/lib/config-provider/SizeContext';
import SelectSearchFrontend from 'components/SelectSearchFrontend';
import StatusTag from 'components/StatusTag';
import { parseProjectTitle } from 'utils/string.utils';
import { getProjectSelect } from '../../store/projectSelect-slice';

interface ProjectSelectProps {
  className?: string;
  label?: boolean;
  name?: string;
  rules?: FormItemProps['rules'];
  size?: SizeType;
  allowClear?: boolean;
  withPersonal?: boolean;
  onlyRetainer?: boolean;
  onlyActive?: boolean;
  bordered?: boolean;
  hidden?: boolean;
  initialOption?: any;
  onChange?: SelectProps<string>['onChange'];
}

const ProjectSelect: FC<ProjectSelectProps> = ({
  className,
  label,
  name,
  rules,
  size,
  allowClear,
  withPersonal,
  onlyRetainer,
  onlyActive,
  bordered,
  hidden,
  initialOption,
  onChange,
}) => {
  return (
    <SelectSearchFrontend
      autoFocus
      noPadding
      allowClear={allowClear}
      className={className}
      size={size}
      label={label ? 'Project' : undefined}
      name={name}
      rules={rules}
      bordered={bordered}
      hidden={hidden}
      placeholder="Select a project"
      searchKeys={['title', 'customer.business_name', 'customer.abbreviation']}
      initialOption={initialOption}
      filter={
        !withPersonal || onlyRetainer
          ? (project) =>
              (withPersonal || project.kind !== 'personal') &&
              (!onlyRetainer || project.is_retainer) &&
              (!onlyActive || project.status !== 'cancelled')
          : undefined
      }
      optionRender={(item) => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '5px 12px',
            background: item.status === 'cancelled' || item?.status === 'completed' ? '#e4e4e4' : undefined,
          }}
        >
          <Row justify="space-between">
            {parseProjectTitle(item)}
            <StatusTag value={item.status} />
          </Row>
          <span className="primary sub">{item.customer?.business_name}</span>
        </div>
      )}
      optionLabel={(item) => parseProjectTitle(item)}
      onChange={onChange}
      storeKeySelector="projectSelect"
      storeAction={getProjectSelect}
    />
  );
};

export default ProjectSelect;
