import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

const current = moment();

const isTotalBelowMinimum = (totalTimer: string) => {
  const totalTimerHours =
    parseInt(totalTimer?.split('h')[0], 10) * 60 + parseInt(totalTimer?.split('h')[1]?.replace('m', ''), 10);

  const hoursNeedPerDay = 7.5;

  const requiredMins: any = {
    Sunday: 0,
    Monday: 0,
    Tuesday: hoursNeedPerDay * 60,
    Wednesday: hoursNeedPerDay * 2 * 60,
    Thursday: hoursNeedPerDay * 3 * 60,
    Friday: hoursNeedPerDay * 4 * 60,
    Saturday: hoursNeedPerDay * 5 * 60,
  };

  const currentRequiredCount = requiredMins[current.format('dddd')];

  return totalTimerHours < currentRequiredCount;
};

export const weeklyLogsSlice = createSlice({
  name: 'weeklyLogs',
  initialState: {
    loading: false,
    data: null,
    today: null,
    todayMinTotal: 0,
    isBelow: false,
    error: null,
  },
  reducers: {
    getWeeklyLogs: () => {},
    setWeeklyLogsLoading: (state) => ({ ...state, loading: true, error: null }),
    setWeeklyLogsData: (state, action) => {
      return {
        ...state,
        loading: false,
        data: action.payload,
        isBelow: action.payload ? isTotalBelowMinimum(action.payload.total) : false,
      };
    },
    setWeeklyLogsToday: (state, action) => {
      return {
        ...state,
        loading: false,
        today: action.payload,
        todayMinTotal: action.payload ? action.payload.reduce((acc: any, item: any) => acc + item.minutes, 0) : 0,
      };
    },
    setWeeklyLogsError: (state, action) => ({ ...state, loading: false, error: action.payload }),
  },
});

export const { setWeeklyLogsData, setWeeklyLogsToday, setWeeklyLogsError, setWeeklyLogsLoading, getWeeklyLogs } =
  weeklyLogsSlice.actions;

export default weeklyLogsSlice.reducer;
