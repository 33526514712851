import { FC } from 'react';
import { FiUsers } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { Form, FormInstance } from 'antd';
import SelectUserAvatar from 'components/SelectUserAvatar';
import { RootState } from 'store';
import { UserResponse } from 'types';

interface SelectFollowersProps {
  state: any;
  form: FormInstance;
  setState: any;
  finalProjectId: any;
}

const SelectFollowers: FC<SelectFollowersProps> = ({ state, form, setState, finalProjectId }) => {
  const user = useSelector((store: RootState) => store.auth.user) as UserResponse;

  const defaultFollowersOptions = [
    ...(!state.followers || !state.followers.find((item: any) => item.uuid === user.uuid)
      ? [{ value: user.uuid, label: user.name, user }]
      : []),
    ...(state.followers?.map((item: any) => ({
      value: item.uuid,
      label: item.name,
      user: item,
    })) ?? []),
  ];

  return (
    <Form.Item
      label={
        <>
          <FiUsers />
          Followers
        </>
      }
      name="followers"
      style={{ marginBottom: 0 }}
    >
      <SelectUserAvatar
        mode="multiple"
        size="middle"
        placeholder="Add followers"
        projectId={finalProjectId}
        defaultOptions={defaultFollowersOptions}
        filterType={['regular_user', 'freelancer']}
        onlyAvatar
        onChange={(_, options) => {
          setState((prevState: any) => ({ ...prevState, followers: options.map((item: any) => item.user) }));

          const remove = state?.responsibles?.find((res: any) => options.find((option: any) => option.value === res.uuid));

          if (remove) {
            setState((prevState: any) => ({ ...prevState, responsibles: [] }));

            form.setFieldsValue({ responsibles: null });
          }
        }}
      />
    </Form.Item>
  );
};

export default SelectFollowers;
