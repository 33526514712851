import { FC } from 'react';
import { DownloadOutlined, FileImageOutlined } from '@ant-design/icons';
import { Card, Col, Divider, Row, Skeleton, Space, Tag, Typography } from 'antd';
import StatusTag from 'components/StatusTag';
import DownloadAsset from 'components/UploadsList/components/DownloadAsset';
import UserAvatar from 'components/UserAvatar';
import { UploadResponse } from 'types/upload-type';
import {
  convertArrToCommaSeparatedString,
  convertMinsToHrsMins,
  convertSnakeCaseToTitleCase,
  parseDateToUSFormat,
} from 'utils/string.utils';
import useTaskActivity from './useTaskActivity';

interface TaskActivityProps {
  task: Record<string, any> | null;
}

const { Title, Text } = Typography;

const TaskActivity: FC<TaskActivityProps> = ({ task }) => {
  const { state, fetchData } = useTaskActivity(task?.uuid);
  const { data, loading, hasMore } = state;

  const renderChangeValue = (value: string, change: Record<string, any>) => {
    if (!change.added || change.added.length === 0) {
      return 'EMPTY';
    }

    if (value === 'estimated_time' || value === 'estimated_time_min') {
      return convertMinsToHrsMins(change.added);
    }

    if (value === 'start_date' || value === 'end_date' || value === 'created_at' || value === 'approval_target') {
      return parseDateToUSFormat(change.added);
    }

    if (value === 'assignees' || value === 'followers' || value === 'tags') {
      return (
        <Space size={5} className="w-full mb-0" direction="vertical">
          {change.added.length > 0 && (
            <Text>
              Added {value}:&nbsp;
              <Text strong>{convertArrToCommaSeparatedString(change.added.map((el: any) => el.name))}</Text>
            </Text>
          )}
          {change.removed?.length > 0 && (
            <Text>
              Removed {value}:&nbsp;
              <Text strong>{convertArrToCommaSeparatedString(change.removed.map((el: any) => el.name))}</Text>
            </Text>
          )}
        </Space>
      );
    }

    if (value === 'requests') {
      return (
        <Space size={5} className="w-full mb-0" direction="vertical">
          {change.added.length > 0 && (
            <Text>
              Related requests:&nbsp;
              <Text strong>
                {convertArrToCommaSeparatedString(
                  change.added.map((el: any) => `${el.company_name}(${parseDateToUSFormat(el.created_at, true)})`),
                )}
              </Text>
            </Text>
          )}
          {change.removed?.length > 0 && (
            <Text>
              Removed requests:&nbsp;
              <Text strong>
                {convertArrToCommaSeparatedString(
                  change.removed.map((el: any) => `${el.company_name}(${parseDateToUSFormat(el.created_at, true)})`),
                )}
              </Text>
            </Text>
          )}
        </Space>
      );
    }

    if (value === 'category') {
      return change.added.name;
    }

    if (value === 'status') {
      return <StatusTag value={change.added} />;
    }

    if (value === 'project') {
      return change.added.title ?? change.added.customer;
    }

    if (value === 'priority') {
      const priorities = [
        { label: 'low', value: 0 },
        { label: 'medium', value: 1, color: 'yellow' },
        { label: 'high', value: 2, color: 'red' },
      ];

      return (
        <Tag color={priorities[change.added].color}>{convertSnakeCaseToTitleCase(priorities[change.added].label, false)}</Tag>
      );
    }

    if (value === 'parent') {
      return change.added.title;
    }

    if (value === 'uploads') {
      return (
        <Row gutter={[8, 8]}>
          {change.added.map((el: UploadResponse) => {
            return (
              <Col span={12} key={el.uuid}>
                <Row
                  wrap={false}
                  justify="space-between"
                  style={{
                    padding: 5,
                    background: '#F0F0F0',
                  }}
                >
                  <Col lg={{ span: 21 }} span={20}>
                    <Text ellipsis style={{ fontWeight: '200 !important' }}>
                      <FileImageOutlined />
                      &nbsp;
                      {el.name}
                    </Text>
                  </Col>
                  <Col>
                    <DownloadAsset uuid={el.uuid} name={el.name} icon={<DownloadOutlined />} />
                  </Col>
                </Row>
              </Col>
            );
          })}
        </Row>
      );
    }

    if (typeof change.added === 'object') {
      return '[OBJECT]';
    }

    return change.added;
  };

  if (loading && data.length === 0) {
    return (
      <Card className="mb-l">
        <Skeleton />
      </Card>
    );
  }

  return (
    <Card className="mb-l">
      {data.length > 0 && (
        <Space direction="vertical" className="w-full mb-l" size={20}>
          {data.map((current: Record<string, any>) => {
            const isCreated = current.action === 'create';
            const changesList = Object.values(current.changes);

            let ignoreDescription = false;

            if (isCreated && changesList.length <= 1) {
              return null;
            }

            return (
              <Space key={current.id} align="start">
                <UserAvatar user={current.author} />

                <div style={{ paddingTop: 5 }}>
                  <Space size={12} className="mb-s">
                    <Title className="mb-0" level={5}>
                      {current.author.name}
                    </Title>

                    <Text className="disabled text-sm">{parseDateToUSFormat(current.created_at, true)}</Text>
                  </Space>

                  <Space size={5} className="w-full" direction="vertical">
                    {changesList.map((changes: any, changesIndex) => {
                      if (isCreated && changesIndex === changesList.length - 1) {
                        return null;
                      }

                      return Object.keys(changes).map((changeKey, index) => {
                        const title = changeKey === 'description_delta' ? 'description' : changeKey;

                        if (changeKey === 'completed_at' || ignoreDescription) {
                          return null;
                        }

                        if (title === 'description') {
                          ignoreDescription = true;
                        }

                        const { added } = changes[changeKey];

                        if (
                          !(changeKey === 'parent' && !added) &&
                          ((Array.isArray(added) && added.length > 0) || (!Array.isArray(added) && added))
                        ) {
                          return changeKey === 'assignees' ||
                            changeKey === 'followers' ||
                            changeKey === 'requests' ||
                            changeKey === 'tags' ? (
                            <div key={index}>{renderChangeValue(changeKey, changes[changeKey])}</div>
                          ) : (
                            <Text key={index}>
                              Updated <Text>{convertSnakeCaseToTitleCase(title, false)}</Text>
                              {title !== 'description' && (
                                <>
                                  {' '}
                                  to{' '}
                                  <Text
                                    strong={
                                      title !== 'uploads' && title !== 'assignees' && title !== 'followers' && title !== 'tags'
                                    }
                                  >
                                    {renderChangeValue(changeKey, changes[changeKey])}
                                  </Text>
                                </>
                              )}
                            </Text>
                          );
                        }

                        return <Text key={index}>Removed {convertSnakeCaseToTitleCase(changeKey, false)} </Text>;
                      });
                    })}
                  </Space>
                </div>
              </Space>
            );
          })}
        </Space>
      )}

      {hasMore && (
        <Divider style={{ marginBottom: 10, marginTop: '-10px' }}>
          {loading ? (
            <Text className="primary">Loading...</Text>
          ) : (
            <Text onClick={() => task && fetchData(task.uuid)} className="primary pointer">
              Load more
            </Text>
          )}
        </Divider>
      )}

      <Space align="start">
        <UserAvatar user={task?.creator} />

        <div style={{ paddingTop: 5 }}>
          <Space size={12} className="mb-s">
            <Title className="mb-0" level={5}>
              {task?.creator?.name}
            </Title>

            <Text className="disabled text-sm">{parseDateToUSFormat(task?.created_at, true)}</Text>
          </Space>

          <div>
            <Text>Task created</Text>
          </div>
        </div>
      </Space>
    </Card>
  );
};

export default TaskActivity;
