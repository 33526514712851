import { useEffect, useState } from 'react';
import { Badge } from 'antd';
import useWebSocket from 'utils/useWebSocket';
import apiRequests from 'utils/api';
import apiRoutes from 'config/apiRoute';

const CountRequestBadge = () => {
  const [countRequests, setCountRequests] = useState<number>();
  const [triggerFetchCount, setTriggerFetchCount] = useState(0);

  useEffect(() => {
    let cancelPromise = false;

    const getCount = async () => {
      const response = await apiRequests.get(apiRoutes.REQUESTS, {
        'page[size]': 1,
        'filters[status][]': ['new'],
      });

      if (cancelPromise) {
        return;
      }

      setCountRequests(response.data.meta.total);
    };

    getCount();

    return () => {
      cancelPromise = true;
    };
  }, [triggerFetchCount]);

  useWebSocket({
    channelName: 'model.changes',
    listen: {
      event: '.app.models.projects.request',
      callback: (event: any) => {
        if (event.payload.status === 'new' && event.action === 'create') {
          setCountRequests((old) => (typeof old === 'number' ? old + 1 : old));
        }

        setTriggerFetchCount((old) => old + 1);
      },
    },
  });

  return <Badge count={countRequests} />;
};

export default CountRequestBadge;
