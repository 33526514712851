import { createSlice } from '@reduxjs/toolkit';

export const permissionsSlice = createSlice({
  name: 'permissions',
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {
    getPermissions: () => {},
    setPermissionsLoading: (state) => ({ ...state, loading: true }),
    setPermissionsData: (state, action) => ({ ...state, loading: false, data: action.payload }),
    setPermissionsError: (state, action) => ({ ...state, loading: false, error: action.payload }),
  },
});

export const { setPermissionsData, setPermissionsError, setPermissionsLoading, getPermissions } = permissionsSlice.actions;

export default permissionsSlice.reducer;
