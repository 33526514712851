import { FC } from 'react';
import { Badge, Collapse, Empty } from 'antd';
import RequestListItem from 'modules/request/components/RequestListItem';
import apiRequests from 'utils/api';
import apiRoutes from 'config/apiRoute';
import appRoutes from 'config/appRoutes';

interface RequestsCollapseProps {
  taskId: string;
  requests: any[];
  onRemove: (request: any) => void;
}

const RequestsCollapse: FC<RequestsCollapseProps> = ({ taskId, requests, onRemove }) => {
  const onRemoveHandler = async (item: any) => {
    await apiRequests.put(`${apiRoutes.PROJECT_TASKS}/${taskId}`, { remove_requests: [item.uuid] });

    onRemove(item);
  };

  return (
    <Collapse className="task-modal-collapse no-padding mb-m">
      <Collapse.Panel
        header="Requests"
        key="request"
        extra={<Badge count={requests.length} className={!requests.length ? 'ant-badge-gray' : ''} showZero />}
      >
        {requests.map((item: any) => (
          <RequestListItem key={item.uuid} to={`${appRoutes.REQUESTS}/${item.uuid}`} item={item} onRemove={onRemoveHandler} />
        ))}

        {!requests.length && <Empty />}
      </Collapse.Panel>
    </Collapse>
  );
};

export default RequestsCollapse;
