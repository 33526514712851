import { AxiosResponse } from 'axios';
import apiRoutes from 'config/apiRoute';
import { call, put, StrictEffect, takeEvery } from 'redux-saga/effects';
import api from 'utils/api';
import asyncErrorHandler from 'utils/asyncErrorHandler';

import { getRoles, setRolesData, setRolesError, setRolesLoading } from './roles-slice';

const { get } = api;

function* getRolesSagaListener(action: any) {
  try {
    yield put(setRolesLoading());
    const res: AxiosResponse = yield call(get, apiRoutes.ROLES, action.payload);
    yield put(setRolesData(res.data));
  } catch (error) {
    yield put(setRolesError(error));
    asyncErrorHandler(error);
  }
}

function* rolesSaga(): Generator<StrictEffect> {
  yield takeEvery(getRoles.type, getRolesSagaListener);
}

export default rolesSaga;
