/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';

export const activeLogsSlice = createSlice({
  name: 'activeLogs',
  initialState: {
    loading: false,
    data: null as Record<string, any>[] | null,
    activeData: null as Record<string, any> | null,
  },
  reducers: {
    getActiveLogs: () => {},
    setActiveLogsLoading: (state) => ({ ...state, loading: true, error: null }),
    setActiveLogsData: (state, action) => ({ ...state, loading: false, data: action.payload }),
    addActiveLogData: (state, action) => ({ ...state, data: [...(state.data ?? []), action.payload] }),
    updateActiveLogData: (state, action) => ({
      ...state,
      data: state.data?.map((item) => (item.uuid === action.payload.uuid ? action.payload : item)) ?? null,
    }),
    removeActiveLogData: (state, action) => ({
      ...state,
      data: state.data?.filter((item) => item.uuid !== action.payload) ?? null,
    }),
    setActiveLogsError: (state, action) => ({ ...state, loading: false, error: action.payload }),
    setActiveLogsActiveData: (state, action) => ({ ...state, activeData: action.payload }),
  },
});

export const {
  setActiveLogsData,
  addActiveLogData,
  updateActiveLogData,
  removeActiveLogData,
  setActiveLogsActiveData,
  setActiveLogsError,
  setActiveLogsLoading,
  getActiveLogs,
} = activeLogsSlice.actions;

export default activeLogsSlice.reducer;
