import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useStopwatch } from 'react-timer-hook';
import { Form } from 'antd';
import moment from 'moment';
import { addActiveLogData, removeActiveLogData, updateActiveLogData } from 'store/container/activeLogs/activeLogs-slice';
import apiRoutes from 'config/apiRoute';
import apiRequests from 'utils/api';
import asyncErrorHandler from 'utils/asyncErrorHandler';
import {
  convertDaysHrsMinsToMins,
  convertHrsMinsToMins,
  convertMinsToHrsMins,
  getDateDifferenceInMinutes,
  getOffsetTimestamp,
  localRandomUuid,
} from 'utils/string.utils';
import useWebSocket from 'utils/useWebSocket';
import { RootState } from 'store';
import { setGlobalLogTrigger } from '../../store/container/globalLog/globalLog-slice';

const useTimer = (
  task_id: string | undefined,
  globalActiveTimer: boolean,
  timerContent: any,
  onAddEntry: any,
  onClose: () => void,
) => {
  const user = useSelector((store: any) => store.auth.user);
  const { loading, activeData: activeLog, data: dataLogs } = useSelector((store: RootState) => store.activeLogs);

  const { minutes, hours, days, isRunning, start, pause, reset } = useStopwatch({ autoStart: false });
  const [saving, setSaving] = useState(false);
  const [loadingTotal, setLoadingTotal] = useState(false);
  const [totalTime, setTotalTime] = useState({
    userTotal: 0,
    taskTotal: 0,
  });

  const current = useMemo(() => {
    if (globalActiveTimer) {
      return activeLog;
    }

    return timerContent ?? dataLogs?.find((x) => x.task.uuid === task_id);
  }, [dataLogs, task_id, timerContent, globalActiveTimer, activeLog]);

  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const updateTimer = async () => {
    const time = convertHrsMinsToMins(form.getFieldValue('time'));
    const date = moment(form.getFieldValue('date')).format('YYYY-MM-DD');
    const description = form.getFieldValue('description');

    if ((!current?.uuid && time === 0) || current?.minutes === time) {
      return;
    }

    setSaving(true);

    try {
      if (current?.uuid) {
        const res = await apiRequests.put(`${apiRoutes.TIME_LOG}/${current.uuid}`, {
          started_at: current.started_at ? moment(Date.now()).format('YYYY-MM-DD HH:mm:ss') : '',
          minutes: time,
          date,
          description: description ?? '',
          ref_token: localRandomUuid,
        });

        dispatch(updateActiveLogData(res.data.data));
      } else {
        const res = await apiRequests.post(`${apiRoutes.TIME_LOG}`, {
          task_id,
          user_id: user.uuid,
          date,
          started_at: '',
          description: description ?? '',
          status: 'paused',
          minutes: time,
          ref_token: localRandomUuid,
        });

        dispatch(addActiveLogData(res.data.data));
      }

      if (onAddEntry) {
        onAddEntry();
      }
    } catch (error) {
      asyncErrorHandler(error);
    } finally {
      setSaving(false);
    }
  };

  const startTimer = async () => {
    if (!dataLogs) return;

    const date = form.getFieldValue('date');

    setSaving(true);

    const fallbackStatus = isRunning;
    const fallbackTimer = convertDaysHrsMinsToMins(days, hours, minutes);
    start();

    try {
      const requests = dataLogs
        .filter((item) => item.status === 'running')
        .map((item: any) => {
          return apiRequests.put(`${apiRoutes.TIME_LOG}/${item.uuid}`, {
            started_at: '',
            status: 'paused',
            date: moment(item.date).format('YYYY-MM-DD'),
            minutes: getDateDifferenceInMinutes(item.started_at) + parseInt(item.minutes, 10),
            ref_token: localRandomUuid,
          });
        });

      const pausedResponses = await Promise.all(requests);

      pausedResponses.forEach((response) => {
        dispatch(updateActiveLogData(response.data.data));
      });

      if (current) {
        const res = await apiRequests.put(`${apiRoutes.TIME_LOG}/${current.uuid}`, {
          started_at: moment(Date.now()).format('YYYY-MM-DD HH:mm:ss'),
          minutes: parseInt(current.minutes, 10),
          date: moment(date).format('YYYY-MM-DD'),
          status: 'running',
          ref_token: localRandomUuid,
        });

        dispatch(updateActiveLogData(res.data.data));
      } else {
        form.setFieldsValue({ time: '00:00' });

        const res = await apiRequests.post(`${apiRoutes.TIME_LOG}`, {
          task_id,
          user_id: user.uuid,
          started_at: moment(Date.now()).format('YYYY-MM-DD HH:mm:ss'),
          description: '',
          date: moment(date).format('YYYY-MM-DD'),
          status: 'running',
          minutes: '0',
          ref_token: localRandomUuid,
        });

        dispatch(addActiveLogData(res.data.data));
      }
    } catch (error) {
      reset(getOffsetTimestamp(fallbackTimer), fallbackStatus);
      asyncErrorHandler(error);
    } finally {
      setSaving(false);
    }
  };

  const pauseTimer = async () => {
    const date = form.getFieldValue('date');

    const fallbackStatus = isRunning;
    const fallbackTimer = convertDaysHrsMinsToMins(days, hours, minutes);
    pause();

    setSaving(true);

    try {
      const res = await apiRequests.put(`${apiRoutes.TIME_LOG}/${current?.uuid}`, {
        started_at: '',
        date: moment(date).format('YYYY-MM-DD'),
        minutes: getDateDifferenceInMinutes(current?.started_at) + parseInt(current?.minutes, 10),
        status: 'paused',
        ref_token: localRandomUuid,
      });

      dispatch(updateActiveLogData(res.data.data));
    } catch (error) {
      reset(getOffsetTimestamp(fallbackTimer), fallbackStatus);

      asyncErrorHandler(error);
    } finally {
      setSaving(false);
    }
  };

  const resetTimer = async () => {
    const fallbackStatus = isRunning;
    const fallbackTimer = convertDaysHrsMinsToMins(days, hours, minutes);
    reset(undefined, false);

    setSaving(true);

    try {
      await apiRequests.delete(`${apiRoutes.TIME_LOG}/${current?.uuid}`);

      dispatch(removeActiveLogData(current?.uuid));

      form.resetFields();

      onClose();
    } catch (error) {
      reset(getOffsetTimestamp(fallbackTimer), fallbackStatus);

      asyncErrorHandler(error);
    } finally {
      setSaving(false);
    }
  };

  const addEntry = async () => {
    const fallbackStatus = isRunning;
    const fallbackTimer = convertDaysHrsMinsToMins(days, hours, minutes);
    reset(undefined, false);

    setSaving(true);

    try {
      const formData = await form.validateFields();
      const time = convertHrsMinsToMins(form.getFieldValue('time'));

      if (current) {
        await apiRequests.put(`${apiRoutes.TIME_LOG}/${current.uuid}`, {
          started_at: '',
          description: formData.description,
          date: moment(formData.date).format('YYYY-MM-DD'),
          minutes: fallbackTimer > time ? fallbackTimer : time,
          status: 'completed',
          ref_token: localRandomUuid,
        });

        dispatch(removeActiveLogData(current?.uuid));
      } else {
        await apiRequests.post(`${apiRoutes.TIME_LOG}`, {
          started_at: moment(Date.now()).format('YYYY-MM-DD HH:mm:ss'),
          description: formData.description,
          date: moment(formData.date).format('YYYY-MM-DD'),
          minutes: fallbackTimer > time ? fallbackTimer : time,
          status: 'completed',
          task_id,
          user_id: formData.user_id ?? user.uuid,
          ref_token: localRandomUuid,
        });

        dispatch(setGlobalLogTrigger());
      }

      const totalResponse = await apiRequests.get(
        `${apiRoutes.PROJECT_TASKS}/${current?.task?.uuid ?? task_id}/time-logs/${user.uuid}`,
      );

      setTotalTime({
        userTotal: parseInt(totalResponse.data.data.sum_user_completed, 10),
        taskTotal: parseInt(totalResponse.data.data.sum_completed, 10),
      });

      form.setFieldsValue({
        description: '',
        time: '00:00',
        date: moment(new Date(Date.now())),
      });

      onClose();

      if (onAddEntry) {
        onAddEntry();
      }
    } catch (error) {
      reset(getOffsetTimestamp(fallbackTimer), fallbackStatus);

      asyncErrorHandler(error);
    } finally {
      setSaving(false);
    }
  };

  const fetchTotalTimeLog = async (abort?: AbortController) => {
    setLoadingTotal(true);

    try {
      const totalResponse = await apiRequests.get(`${apiRoutes.PROJECT_TASKS}/${task_id}/time-logs/${user.uuid}`, {}, {}, abort);

      setTotalTime({
        userTotal: parseInt(totalResponse.data.data.sum_user_completed, 10),
        taskTotal: parseInt(totalResponse.data.data.sum_completed, 10),
      });
    } catch (error) {
      if (error.code === 'ERR_CANCELED') return;
      asyncErrorHandler(error);
    } finally {
      setLoadingTotal(false);
    }
  };

  const onChangeTimer = (value: string) => {
    if (!current) return;

    const offsetTime = new Date();

    offsetTime.setMinutes(offsetTime.getMinutes() + convertHrsMinsToMins(value));

    reset(offsetTime, current.status === 'running');
  };

  useEffect(() => {
    const abort = new AbortController();
    const buttonClose = document.querySelector('.ant-modal-close');

    const handleClose = () => {
      onClose();
    };

    if (task_id && buttonClose) {
      buttonClose.addEventListener('click', handleClose);
    }

    if (task_id) {
      fetchTotalTimeLog(abort);
    }

    return () => {
      abort.abort();

      if (task_id && buttonClose) {
        buttonClose.removeEventListener('click', handleClose);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task_id]);

  useEffect(() => {
    if (current) {
      form.setFieldsValue({ time: convertMinsToHrsMins(convertDaysHrsMinsToMins(days, hours, minutes)) });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minutes, hours, days]);

  useEffect(() => {
    if (current) {
      const offsetTime = new Date();

      offsetTime.setMinutes(
        offsetTime.getMinutes() +
          parseInt(current.minutes, 10) +
          (!current.started_at ? 0 : getDateDifferenceInMinutes(current.started_at)),
      );

      reset(offsetTime, current.status === 'running');

      form.setFieldsValue({ ...current, date: moment(current.date), user_id: current.user.uuid });
    }
  }, [current, form, reset]);

  useWebSocket({
    channelName: 'model.changes',
    listen: {
      event: '.app.models.projects.timelog',
      callback: (event: any) => {
        if (event.payload.ref_token === localRandomUuid) return;

        if (task_id && event.payload.task_id === task_id) {
          fetchTotalTimeLog();

          if (event.id === current?.uuid && event.action === 'delete') {
            reset(undefined, false);

            form.setFieldsValue({ date: moment(), time: '00:00', description: '' });
          }
        }
      },
    },
  });

  return {
    minutes,
    hours,
    days,
    isRunning,
    form,
    totalTime,
    saving,
    loading,
    loadingTotal,
    current,
    start,
    pause,
    startTimer,
    pauseTimer,
    addEntry,
    updateTimer,
    resetTimer,
    onChangeTimer,
  };
};

export default useTimer;
