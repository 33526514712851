import { AxiosResponse } from 'axios';
import apiRoutes from 'config/apiRoute';
import { call, put, StrictEffect, takeEvery } from 'redux-saga/effects';
import api from 'utils/api';
import asyncErrorHandler from 'utils/asyncErrorHandler';

import { getPermissions, setPermissionsData, setPermissionsError, setPermissionsLoading } from './permissions-slice';

const { get } = api;

function* getPermissionsSagaListener(action: any) {
  try {
    yield put(setPermissionsLoading());
    const res: AxiosResponse = yield call(get, apiRoutes.PERMISSIONS, action.payload);
    yield put(setPermissionsData(res?.data?.permissions));
  } catch (error) {
    yield put(setPermissionsError(error));
    asyncErrorHandler(error);
  }
}

function* permissionsSaga(): Generator<StrictEffect> {
  yield takeEvery(getPermissions.type, getPermissionsSagaListener);
}

export default permissionsSaga;
