import { createAppTableSaga, createPersist } from 'components/AppTable/store';
import storeTableTaskFactory from 'components/TasksListTabs/storeTableTaskFactory';

export const taskPersonalSlice = storeTableTaskFactory({
  name: 'tableTasksPersonal' as const,
  onlyTable: true,
  initialState: {
    fixedParams: { personal_only: true, ignore_project: true },
  },
});

export const tasksPersonalSaga = createAppTableSaga(taskPersonalSlice);

export const tasksPersonalReducer = createPersist(taskPersonalSlice);
