import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  data: null,
  error: null,
};

export const contactsSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    getContacts: () => {},
    getClearContacts: (state) => ({ ...state, loading: true, data: null }),
    clearContacts: () => initialState,
    setContactsLoading: (state) => ({ ...state, loading: true }),
    setContactsData: (state, action) => ({ ...state, loading: false, data: action.payload }),
    setContactsError: (state, action) => ({ ...state, loading: false, error: action.payload }),
  },
});

export const { setContactsData, setContactsError, setContactsLoading, getContacts, clearContacts, getClearContacts } =
  contactsSlice.actions;

export default contactsSlice.reducer;
