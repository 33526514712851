import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveLogs, removeActiveLogData } from 'store/container/activeLogs/activeLogs-slice';
import { localRandomUuid } from 'utils/string.utils';
import useWebSocket from 'utils/useWebSocket';

const useHeader = () => {
  const user = useSelector((store: any) => store.auth.user);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getActiveLogs());
  }, [dispatch]);

  useWebSocket({
    channelName: 'model.changes',
    listen: {
      event: '.app.models.projects.timelog',
      callback: (event: any) => {
        if (event.payload.ref_token === localRandomUuid) return;

        if (event.payload.user_id === user.uuid) {
          if (event.action !== 'delete') {
            dispatch(removeActiveLogData(event.id));
          } else {
            dispatch(getActiveLogs());
          }
        }
      },
    },
  });

  return {
    user,
  };
};

export default useHeader;
