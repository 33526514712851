import Quill from 'quill';
import type Parchment from 'parchment';

const LinkBlot: typeof Parchment.Inline = Quill.import('formats/link');

class ClickableLink extends LinkBlot {
  static create(value: string) {
    const node = super.create(value) as HTMLElement;
    node.setAttribute('contenteditable', 'false');

    return node;
  }
}

export default ClickableLink;
