import { FC, useState } from 'react';
import { Typography } from 'antd';
import debounce from 'lodash/debounce';
import styles from './index.module.less';

const { Paragraph } = Typography;

interface EditableParagraphInputProps {
  value?: string;
  maxLength?: number;
  onChange?: (value: string | null) => void;
  onChangeDebounce?: (value: string | null) => void;
}

const debounceCallback = debounce((callback: (value: string | null) => void, value: string | null) => {
  callback(value);
}, 600);

const EditableParagraphInput: FC<EditableParagraphInputProps> = ({ value, maxLength, onChange, onChangeDebounce }) => {
  const [editing, setEditing] = useState(false);

  const isPlaceholder = !value && !editing;

  return (
    <div className="w-full" /* onBlur={() => setEditing(false)} */>
      {isPlaceholder ? (
        <Paragraph className={`${styles.editableTitleTextArea} ${styles.placeholder}`} onClick={() => setEditing(true)}>
          Type in a title
        </Paragraph>
      ) : (
        <Paragraph
          className={styles.editableTitleTextArea}
          editable={{
            editing,
            onEnd: () => {
              setEditing(false);
            },
            maxLength,
            onChange: (newValue) => {
              onChange?.(newValue);

              if (onChangeDebounce) {
                debounceCallback(onChangeDebounce, newValue);
              }
            },
          }}
          onClick={() => setEditing(true)}
        >
          {value}
        </Paragraph>
      )}
    </div>
  );
};

export default EditableParagraphInput;
