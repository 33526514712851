import apiRoutes from 'config/apiRoute';
import { call, put, StrictEffect, select, takeLatest } from 'redux-saga/effects';
import api from 'utils/api';
import { AxiosResponse } from 'utils/interface';

import {
  addActiveLogData,
  getActiveLogs,
  removeActiveLogData,
  setActiveLogsActiveData,
  setActiveLogsData,
  setActiveLogsError,
  setActiveLogsLoading,
  updateActiveLogData,
} from './activeLogs-slice';

/**
 * worker saga
 */
const { get } = api;

export function* getActiveLogsSagaListener(): any {
  const user = yield select((store) => store.auth.user);

  try {
    yield put(setActiveLogsLoading());

    const res: AxiosResponse = yield call(get, `${apiRoutes.TIME_LOG}`, {
      'filters[user.uuid][]': user?.uuid,
      'filters[status]': ['running', 'paused'],
    });

    yield put(setActiveLogsData(res.data.data));
  } catch (e) {
    yield put(setActiveLogsError(e));
  }
}

export function* updatedActiveLogsSagaListener(): any {
  const activeLogs = yield select((store) => store.activeLogs.data);

  const active = activeLogs?.find((item: any) => item.status === 'running') ?? null;

  yield put(setActiveLogsActiveData(active));
}

/**
 * watcher saga
 */
function* activeLogsSaga(): Generator<StrictEffect> {
  yield takeLatest(getActiveLogs.type, getActiveLogsSagaListener);
  yield takeLatest(setActiveLogsData.type, updatedActiveLogsSagaListener);
  yield takeLatest(addActiveLogData.type, updatedActiveLogsSagaListener);
  yield takeLatest(updateActiveLogData.type, updatedActiveLogsSagaListener);
  yield takeLatest(removeActiveLogData.type, updatedActiveLogsSagaListener);
}

export default activeLogsSaga;
