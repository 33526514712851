import { FC, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import AddProjectModal from 'modules/project/components/AddProjectModal';
import AddContactModal from 'modules/client/components/AddContactModal';
import appRoutes from 'config/appRoutes';
import usePermissions from 'utils/usePermissions';
import { checkIsAdmin } from 'utils/auth';
import { UserResponse } from 'types';

interface ActionDropdownProps {
  user: UserResponse;
}

const ActionDropdown: FC<ActionDropdownProps> = ({ user }) => {
  const [projectModal, setProjectModal] = useState(false);
  const [inviteModal, setInviteModal] = useState(false);

  const { CONTACTS_CREATE, TASKS_CREATE, CUSTOMERS_CREATE, PROJECTS_CREATE } = usePermissions([
    'CONTACTS_CREATE',
    'TASKS_CREATE',
    'CUSTOMERS_CREATE',
    'PROJECTS_CREATE',
    'PROJECTS_UPDATE',
  ]);

  const isRegularUser = checkIsAdmin(user);

  return (
    <>
      <AddProjectModal key={`${projectModal}`} visible={projectModal} onClose={() => setProjectModal(false)} />

      {inviteModal && (
        <AddContactModal
          visible={inviteModal ? 'new' : null}
          onClose={() => {
            setInviteModal(false);
          }}
        />
      )}

      <Dropdown
        placement="bottomRight"
        trigger={[isMobile ? 'click' : 'hover']}
        overlay={
          <Menu style={{ minWidth: 160 }}>
            {TASKS_CREATE && (
              <Menu.Item key="new-task">
                <Link to="?task_uuid=create&task_any_project">New task</Link>
              </Menu.Item>
            )}

            {PROJECTS_CREATE && isRegularUser && (
              <Menu.Item key="new-project" onClick={() => setProjectModal(true)}>
                New project
              </Menu.Item>
            )}

            {CUSTOMERS_CREATE && isRegularUser && (
              <Menu.Item key="new-client">
                <Link to={`${appRoutes.CLIENTS}/create`}>New client</Link>
              </Menu.Item>
            )}

            {CONTACTS_CREATE && isRegularUser && (
              <Menu.Item key="new-user">
                <Link to={`${appRoutes.CONTACTS}/add`}>New user</Link>
              </Menu.Item>
            )}

            {isRegularUser && (
              <Menu.Item key="new-invite" onClick={() => setInviteModal(true)}>
                New invite
              </Menu.Item>
            )}

            {isRegularUser && (
              <Menu.Item key="new-request">
                <Link to={`${appRoutes.REQUESTS}/create`}>New request</Link>
              </Menu.Item>
            )}
          </Menu>
        }
      >
        <Button type="primary" size="large">
          <PlusOutlined style={{ fontSize: 16 }} />
          New
        </Button>
      </Dropdown>
    </>
  );
};

export default ActionDropdown;
