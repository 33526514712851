import { createSlice } from '@reduxjs/toolkit';

interface InitialState {
  project?: any;
  savedTrigger: number;
  closeTrigger: number;
}

const initialState: InitialState = {
  project: undefined,
  savedTrigger: 0,
  closeTrigger: 0,
};

export const globalTaskModalSlice = createSlice({
  name: 'globalTaskModal',
  initialState,
  reducers: {
    setGlobalTaskModal: (
      state,
      {
        payload,
      }: {
        payload: {
          project?: any;
        };
        type: string;
      },
    ) => ({
      ...state,
      project: payload.project,
    }),
    setGlobalTaskModalSavedTrigger: (state) => ({ ...state, savedTrigger: state.savedTrigger + 1 }),
    setGlobalTaskModalClosedTrigger: (state) => ({ ...state, closeTrigger: state.closeTrigger + 1 }),
  },
});

export const { setGlobalTaskModal, setGlobalTaskModalSavedTrigger, setGlobalTaskModalClosedTrigger } =
  globalTaskModalSlice.actions;

export default globalTaskModalSlice.reducer;
