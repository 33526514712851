import { FC } from 'react';
import { useHistory } from 'react-router';
import { Space } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { convertMinsToHrsMins } from 'utils/string.utils';
import appRoutes from 'config/appRoutes';

import useWeeklyTimer from './useWeeklyTimer';

interface WeeklyTimerProps {}

const WeeklyTimer: FC<WeeklyTimerProps> = () => {
  const { weeklyLogs } = useWeeklyTimer();
  const history = useHistory();

  if (!weeklyLogs.data || !weeklyLogs.today) {
    return null;
  }

  const formatTime = (value: string) => {
    if (!value) return '';

    const hours = parseInt(value.split('h')[0], 10);
    const mins = parseInt(value.split('h')[1].replaceAll('m', ''), 10);
    return `${convertMinsToHrsMins(hours * 60 + mins)}`;
  };

  return (
    <div
      onClick={() => {
        history.push(`${appRoutes.TIME_LOG_REPORT}?current_week=true`);
      }}
      style={{ cursor: 'pointer' }}
    >
      <Space>
        <div className="mb-0" style={{ lineHeight: '20px', fontSize: 14 }}>
          <p style={{ marginBottom: 0, whiteSpace: 'nowrap' }}>T: {convertMinsToHrsMins(weeklyLogs.todayMinTotal)}</p>
          <p style={{ color: weeklyLogs.isBelow ? '#FF6672' : undefined, marginBottom: 0, whiteSpace: 'nowrap' }}>
            W: {formatTime(weeklyLogs.data.total)}
          </p>
        </div>

        {weeklyLogs.loading && <LoadingOutlined spin />}
      </Space>
    </div>
  );
};

export default WeeklyTimer;
