import { FC } from 'react';
import { Helmet } from 'react-helmet';
import { MenuItemBaseLinkType } from 'components/Layout/components/Sidebar/config';
import environment from 'config/environment';

interface AppHeaderProps {
  activeRoute?: MenuItemBaseLinkType;
}

const AppHeader: FC<AppHeaderProps> = ({ activeRoute }) => {
  return (
    <Helmet>
      <meta charSet="utf-8" />

      <title>
        {environment.IS_DEV_ENV ? 'Dev - ' : ''}Lionshare - {activeRoute?.label ?? 'App'}
      </title>

      <link rel="canonical" href={activeRoute?.path ?? '/'} />
    </Helmet>
  );
};

export default AppHeader;
