/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  data: null,
  error: null,
};

export const analyticsSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    getAnalytics: (state, action) => {},
    setAnalyticsLoading: (state) => ({ ...state, loading: true }),
    setAnalyticsData: (state, action) => ({ ...state, loading: false, data: action.payload }),
    clearAnalyticsData: () => initialState,
    setAnalyticsError: (state, action) => ({ ...state, loading: false, error: action.payload }),
  },
});

export const { getAnalytics, setAnalyticsLoading, setAnalyticsData, clearAnalyticsData, setAnalyticsError } =
  analyticsSlice.actions;

export default analyticsSlice.reducer;
