/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import { CheckOutlined, ExclamationOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Space } from 'antd';
import StatusTag from 'components/StatusTag';
import { FC } from 'react';

interface PriorityDropdownProps {
  value?: number;
  onChange?: any;
}

const PriorityDropdown: FC<PriorityDropdownProps> = ({ value, onChange }) => {
  const options = [
    { label: 'low', value: 0 },
    { label: 'medium', value: 1 },
    { label: 'high', value: 2 },
  ];

  return (
    <Dropdown
      overlay={
        <Menu activeKey={`${value}`}>
          {options?.map((option) => {
            return (
              <Menu.Item
                onClick={() => {
                  onChange?.(option.value);
                }}
                key={`${option.value}`}
              >
                <Space>
                  <div style={{ width: 10 }}>
                    <CheckOutlined style={{ color: value !== option?.value ? 'transparent' : undefined }} />
                  </div>{' '}
                  <StatusTag value={option.label} />
                </Space>
              </Menu.Item>
            );
          })}
        </Menu>
      }
    >
      <ExclamationOutlined style={{ fontSize: 22, color: value === 0 ? undefined : value === 1 ? 'orange' : 'red' }} />
    </Dropdown>
  );
};

export default PriorityDropdown;
