import apiRoutes from 'config/apiRoute';
import moment from 'moment';
import { call, put, StrictEffect, select, takeLatest } from 'redux-saga/effects';
import api from 'utils/api';
import { AxiosResponse } from 'utils/interface';
import {
  getWeeklyLogs,
  setWeeklyLogsData,
  setWeeklyLogsError,
  setWeeklyLogsLoading,
  setWeeklyLogsToday,
} from './weeklyLogs-slice';

const { get } = api;

const current = moment();

const startOfWeek = current.startOf('week').format('YYYY-MM-DD');
const endOfWeek = current.endOf('week').format('YYYY-MM-DD');

export function* getActiveLogsSagaListener(): any {
  const user = yield select((store) => store.auth.user);

  try {
    yield put(setWeeklyLogsLoading());

    const res: AxiosResponse = yield call(get, `${apiRoutes.TIME_LOG_REPORT}`, {
      'page[size]': 500,
      'filters[status][]': 'completed',
      'filters[user_uuid][]': user?.uuid,
      date_from: startOfWeek,
      date_to: endOfWeek,
    });

    yield put(setWeeklyLogsToday(res.data.data.filter((item: any) => moment(item.date).isSame(moment(), 'day'))));
    yield put(setWeeklyLogsData(res.data));
  } catch (e) {
    yield put(setWeeklyLogsError(e));
  }
}

function* weeklyLogsSaga(): Generator<StrictEffect> {
  yield takeLatest(getWeeklyLogs.type, getActiveLogsSagaListener);
}

export default weeklyLogsSaga;
