import { CSSProperties, FC } from 'react';
import { Button, Col, FormInstance, Popconfirm, Row, Typography } from 'antd';
import { convertMinsToHrsMins, convertSnakeCaseToTitleCase } from 'utils/string.utils';
import styles from './Timer.module.less';
import TimerForm from './TimerForm';

const { Text } = Typography;

interface TimerContainerProps {
  dataLog?: Record<string, any>;
  form: FormInstance;
  style?: CSSProperties;
  projectId?: string;
  title?: string;
  taskStatus?: string;
  totalTime: number;
  loading?: boolean;
  loadingTotal?: boolean;
  globalActiveTimer?: boolean;
  needsAlert?: boolean;
  showUserSelection?: boolean;
  onAddEntry: () => void;
  onChangeTimer: (value: string) => void;
  onResetTimer: () => void;
  onCancel: () => void;
}

const TimerContainer: FC<TimerContainerProps> = ({
  dataLog,
  form,
  title,
  style,
  projectId,
  taskStatus,
  totalTime,
  loading,
  loadingTotal,
  globalActiveTimer,
  needsAlert,
  showUserSelection,
  onAddEntry,
  onChangeTimer,
  onResetTimer,
  onCancel,
}) => {
  taskStatus = taskStatus ?? dataLog?.task?.status;

  return (
    <div className={styles.dropdown} style={style}>
      {globalActiveTimer || title ? (
        <div className={styles.head}>
          <span>{title ?? dataLog?.task?.title}</span>
        </div>
      ) : undefined}
      <div className={styles.body}>
        <TimerForm form={form} projectId={projectId} showUserSelection={showUserSelection} onChangeTimer={onChangeTimer} />

        <div className="mb-m">
          <Text type={loadingTotal ? 'secondary' : undefined}>
            Your time spent:&nbsp;
            {convertMinsToHrsMins(totalTime).split(':')[0]}h&nbsp;
            {convertMinsToHrsMins(totalTime).split(':')[1]}m
          </Text>
        </div>

        <Row justify="space-between">
          <Row gutter={3}>
            <Col>
              {(taskStatus === 'completed' || taskStatus === 'cancelled') && needsAlert ? (
                <Popconfirm
                  placement="topLeft"
                  disabled={loading}
                  onConfirm={onAddEntry}
                  title={`This task is ${convertSnakeCaseToTitleCase(taskStatus)}, are you sure you want to add the timelog?`}
                >
                  <Button loading={loading} type="primary">
                    Add entry
                  </Button>
                </Popconfirm>
              ) : (
                <Button loading={loading} onClick={onAddEntry} type="primary">
                  Add entry
                </Button>
              )}
            </Col>

            <Col>
              <Button className="primary" onClick={onCancel} type="text">
                Cancel
              </Button>
            </Col>
          </Row>

          <Button onClick={onResetTimer} disabled={!dataLog} className="primary" type="text">
            Reset timer
          </Button>
        </Row>
      </div>
    </div>
  );
};

export default TimerContainer;
