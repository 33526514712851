/* eslint-disable react/no-danger */
import { FC, ReactNode } from 'react';
import { minCharSpan } from 'utils/string.utils';

interface MinSpanProps {
  text: string | any;
  count: number;
  children?: ReactNode;
}

const MinSpan: FC<MinSpanProps> = ({ text, count, children }) => {
  const extra = minCharSpan(text, count);

  return (
    <span>
      {children}
      {extra ? <span dangerouslySetInnerHTML={{ __html: extra }} /> : undefined}
    </span>
  );
};

export default MinSpan;
