/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useRef, useState } from 'react';
import { Checkbox, Col, Form, message, Modal, Row, Typography } from 'antd';
import InviteContactSelectSearch from 'components/InviteContactSelectSearch';
import SelectSearchFrontend from 'components/SelectSearchFrontend';
import { getCustomerSelect } from 'modules/customer/store/customerSelect-slice';
import apiRoutes from 'config/apiRoute';
import apiRequests from 'utils/api';
import asyncErrorHandler from 'utils/asyncErrorHandler';

interface AddContactModalProps {
  visible: null | 'new' | 'resend-contact' | 'resend-pending';
  pendingInvites?: any[] | null;
  onClose: () => void;
  onFinish?: () => void;
  modalData?: any;
  clientUuid?: string;
}

const { Text } = Typography;

const AddContactModal: FC<AddContactModalProps> = ({ visible, pendingInvites, onClose, onFinish, modalData, clientUuid }) => {
  const [form] = Form.useForm();
  const [saving, setSaving] = useState(false);
  const [client, setClient] = useState<any>(null);
  const [contact, setContact] = useState<any>(modalData);
  const isNonUser = typeof contact === 'string';

  const [loadingPendingInvites, setLoadingPendingInvites] = useState(false);

  const pendingInvitesRef = useRef(pendingInvites);

  const setCheckboxFromContact = (target: any) => {
    const mainEmails = [] as any[];

    ['leadsie_manage_link', 'login_register'].forEach((el) => {
      if (target[el]) {
        mainEmails.push(el);
      }
    });

    form.setFieldsValue({ main_emails: mainEmails });
  };

  useEffect(() => {
    if (clientUuid) {
      return;
    }

    const fetchPendingInvites = async () => {
      setLoadingPendingInvites(true);
      try {
        const res = await apiRequests.get(`${apiRoutes.CUSTOMER_INVITE}`, {
          'filters[status][]': 'pending',
          'filters[customer.uuid][]': client.uuid,
        });

        pendingInvitesRef.current = res?.data?.data;
      } catch (error) {
        asyncErrorHandler(error);
      } finally {
        setLoadingPendingInvites(false);
      }
    };

    if (client) {
      fetchPendingInvites();
    } else {
      pendingInvitesRef.current = null;
    }
  }, [client, clientUuid]);

  useEffect(() => {
    if (isNonUser && visible === 'new') {
      form.setFieldsValue({ main_emails: ['login_register'] });
    }
  }, [isNonUser]);

  useEffect(() => {
    if (contact && visible === 'new') {
      const email = isNonUser ? contact : contact?.email;

      const target = pendingInvitesRef.current?.find((x) => x.email === email);

      if (target) {
        setCheckboxFromContact(target);
      }
    }
  }, [contact]);

  useEffect(() => {
    if (modalData && visible === 'resend-pending') {
      setCheckboxFromContact(modalData);
    }
  }, [modalData]);

  const handleSave = async () => {
    try {
      setSaving(true);
      const values = await form.validateFields();

      const allValues = {
        social_media: false,
        creative_package: false,
        video_production: false,
        website: false,
        advertising: false,
        schedule_call_link: false,

        welcome_kit: false,
        login_register: false,
        leadsie_manage_link: false,
      };

      values.main_emails?.forEach((i: keyof typeof allValues) => {
        allValues[i] = true;
      });

      const newData = {
        ...allValues,
        leadsie_social_link: allValues.leadsie_manage_link,
      };

      const email = isNonUser ? contact : contact?.email;

      const emailHasPending = pendingInvitesRef.current?.find((x) => x.email === email);

      await apiRequests.post(
        visible !== 'resend-pending' && !emailHasPending
          ? apiRoutes.CUSTOMER_INVITE
          : `${apiRoutes.CUSTOMER_INVITE}/${(emailHasPending ?? contact)?.uuid}/resend`,

        {
          ...newData,
          email,
          customer_id: clientUuid ?? client.uuid,
          user_id: visible !== 'resend-pending' ? contact?.user?.uuid : undefined,
        },
      );
      message.success('Invitation sent.');
      setSaving(false);
      onClose();
      onFinish?.();
    } catch (error) {
      asyncErrorHandler(error);
      setSaving(false);
    }
  };

  return (
    <Modal
      confirmLoading={saving}
      okText="Save"
      onOk={handleSave}
      title={<Text className="primary">Invite contact</Text>}
      visible={!!visible}
      onCancel={() => {
        onClose();
        form.resetFields();
        setContact(null);
      }}
      okButtonProps={{ disabled: (!contact && visible === 'new') || loadingPendingInvites }}
    >
      {visible === 'new' && (
        <Form form={form} layout="vertical" initialValues={{ contacts: isNonUser ? contact : undefined }}>
          {!clientUuid && (
            <SelectSearchFrontend
              autoFocus
              allowClear
              label="Select client:"
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                },
              ]}
              placeholder="Select a client"
              searchKeys={['phone', 'business_name', 'status', 'email', 'website', 'abbreviation']}
              optionLabel="business_name"
              storeKeySelector="customerSelect"
              storeAction={getCustomerSelect}
              onChange={(_, item: any) => {
                setClient(item?.resource ?? null);
              }}
            />
          )}

          <InviteContactSelectSearch
            name="contacts"
            api={apiRoutes.CONTACTS}
            label="Select contact:"
            placeholder="Select a contact"
            labelKeys={['user.name', 'email']}
            autoFocus={!!clientUuid}
            allowClear
            render={(el: any) => {
              return (
                <Row justify="space-between">
                  <Text>{el?.name}</Text>
                  <Text className="primary">{el?.email}</Text>
                </Row>
              );
            }}
            onInviteSelect={(value: string) => {
              form.setFieldsValue({ contacts: value });
              setContact(value);
            }}
            onChange={(value: any) => {
              setContact(value);
            }}
          />
        </Form>
      )}
      <Form colon={false} layout="vertical" form={form}>
        <Form.Item label="Include:" name="main_emails">
          <Checkbox.Group style={{ width: '100%' }}>
            <Row gutter={[12, 12]}>
              <Col>
                <Checkbox value="leadsie_manage_link">Leadsie</Checkbox>
              </Col>
              <Col>
                <Checkbox disabled={isNonUser} value="login_register">
                  Login/Register
                </Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddContactModal;
