import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Popconfirm, Row, Space, Typography } from 'antd';
import { CloseCircleOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';
import SelectSearch from 'components/SelectSearch';
import StatusTag from 'components/StatusTag';
import apiRoutes from 'config/apiRoute';
import { parseTaskTitle } from 'utils/string.utils';

interface ParentTaskProps {
  task: Record<string, any>;
  onChange: (parent: any) => void;
}

const { Text } = Typography;

const ParentTask: FC<ParentTaskProps> = ({ task, onChange }) => {
  const [edit, setEdit] = useState(false);

  return edit ? (
    <Space>
      <SelectSearch
        api={apiRoutes.PROJECT_TASKS}
        formItemClassName="mb-0"
        labelKey="task_title"
        onChange={(value: any) => {
          onChange(value);
          setEdit(false);
        }}
        defaultParams={{
          'filters[project.uuid][]': task.project.uuid,
          'filters[task_status]': [
            'todo',
            'in_progress',
            'waiting_approval',
            'working_on_now',
            'pending',
            'approved',
            'needs_attention',
          ],
        }}
        filterBeforeRender={(preRenderedData: any) => {
          const newData = [...preRenderedData];
          return newData.filter((el: any) => el.value !== task.uuid && el.value !== task.parent.uuid);
        }}
        placeholder="Task name"
        render={(el: any) => (
          <Row justify="space-between">
            <Text ellipsis>{parseTaskTitle(el)}</Text>
            <StatusTag value={el.task_status} />
          </Row>
        )}
        style={{ width: '400px' }}
      />
      <Button danger icon={<CloseOutlined />} onClick={() => setEdit(false)} />
    </Space>
  ) : (
    <Space size={10}>
      <Link className="mb-0" to={`?task_uuid=${task.parent.uuid}`}>
        {task.parent.title ?? task.parent.task_title}
      </Link>

      <EditOutlined onClick={() => setEdit(true)} className="pointer" />

      <Popconfirm title="Do you want to remove the link to parent task?" onConfirm={() => onChange(null)}>
        <CloseCircleOutlined className="red" />
      </Popconfirm>
    </Space>
  );
};

export default ParentTask;
