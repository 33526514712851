import { FC } from 'react';

import { Layout } from 'antd';

import style from './actionbarLayout.module.less';

const { Content } = Layout;

interface ActionBarLayoutProps {
  overflowScroll?: boolean;
}

const ActionBarLayout: FC<ActionBarLayoutProps> = ({ overflowScroll, children }) => {
  return (
    <Content className={`page-scrollbar ${style.content}`} style={{ overflowY: overflowScroll ? 'scroll' : undefined }}>
      {children}
    </Content>
  );
};

export default ActionBarLayout;
