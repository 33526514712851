import { FC } from 'react';
import { Result } from 'antd';

interface ErrorResultProps {
  error: any;
}

const ErrorResult: FC<ErrorResultProps> = ({ error }) => {
  const message = error?.response?.data?.message ?? 'Something went wrong. Please reload the page.';

  return <Result status="warning" title={message} />;
};

export default ErrorResult;
