/* eslint-disable import/prefer-default-export */

import ENVIRONMENT from 'config/environment';
import { UploadFileApi } from 'types/upload-type';

export const arrayMove = (arr: any, oldIndex: number, newIndex: number): [] => {
  if (newIndex >= arr.length) {
    let k = newIndex - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  return arr;
};

export const arrayContains = (array: any[], key: string, value: string | number | boolean) => {
  if (!Array.isArray(array)) return false;

  return !!array?.find((el) => el[key] === value);
};

export const randomArrayItem = (array: any[]) => array[Math.floor(array.length * Math.random())];

export function mapModelToUploadFileApi(uploads: any[]) {
  return uploads.map(
    (upload): UploadFileApi => ({
      uid: upload.uuid,
      name: upload.name,
      url: `${ENVIRONMENT.REACT_APP_UPLOADS_PATH}/${upload.uuid}.${upload.extension}`,
      status: 'done',
      extension: upload.extension,
      size: Number(upload.size),
      response: {
        uuid: upload.uuid,
        url: `${ENVIRONMENT.REACT_APP_UPLOADS_PATH}/${upload.uuid}.${upload.extension}`,
        stated: true,
      },
    }),
  );
}
