import { FC } from 'react';
import { Spin } from 'antd';

const Loader: FC = () => (
  <div
    style={{
      width: '100%',
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <Spin tip="Loading...">
      <div style={{ width: 100, height: 30 }} />
    </Spin>
  </div>
);

export default Loader;
