/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { GET_ANSWERS_FULLFILLED, GET_ANSWERS_PENDING, GET_ANSWERS_REJECTED } from '../actions';

const answers = (state = { pending: false, data: [], error: null }, action: any) => {
  switch (action.type) {
    case GET_ANSWERS_PENDING:
      return {
        ...state,
        pending: true,
        error: null,
      };
    case GET_ANSWERS_FULLFILLED:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case GET_ANSWERS_REJECTED:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default answers;
