/* eslint-disable react/no-danger */
import { CSSProperties, forwardRef, useEffect, useImperativeHandle, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Button } from 'antd';
import { AxiosResponse } from 'axios';
import Lightbox, { LightboxProps } from 'yet-another-react-lightbox';
import Counter from 'yet-another-react-lightbox/dist/plugins/counter';
import Thumbnails from 'yet-another-react-lightbox/dist/plugins/thumbnails';
import Zoom from 'yet-another-react-lightbox/dist/plugins/zoom';
import 'yet-another-react-lightbox/dist/styles.css';
import 'yet-another-react-lightbox/dist/plugins/counter/counter.css';
import 'yet-another-react-lightbox/dist/plugins/thumbnails/thumbnails.css';
import { fromUint8Array } from 'js-base64';
import * as Y from 'yjs';
import ReactQuill, { Quill } from 'react-quill';
import { TextProps } from 'antd/lib/typography/Text';
import AutoLinks from 'quill-auto-links';
import ImageUploader from 'quill-image-uploader';
import 'quill-mention';
import apiRequests from 'utils/api';
import asyncErrorHandler from 'utils/asyncErrorHandler';
import { debounceAsync } from 'utils/debounce';
import { isImgElement } from 'utils/guardTypes';
import apiRoutes from 'config/apiRoute';
import type { UploadFileApi } from 'types/upload-type';
import type { CollaborativeEditorOptions } from 'types/richTextEditor';
import CustomTheme from './CustomTheme';
import ClickableLink from './ClickableLink';
import CustomCursors from './CustomCursors';
import { useCollaborativeEditor } from './useCollaborativeEditor';
import './index.style.less';

Quill.register(
  {
    'modules/autoLinks': AutoLinks,
    'modules/imageUploader': ImageUploader,
    'themes/snow': CustomTheme,
    'formats/link': ClickableLink,
    'modules/customCursors': CustomCursors,
  },
  true,
);

export interface RichTextEditorProps {
  id?: string;
  className?: string;
  wrapperClassName?: string;
  projectId?: string;
  placeholder?: string;
  collaborativeEditor?: CollaborativeEditorOptions;
  readonly?: boolean;
  noContainer?: boolean;
  containerFullHeight?: boolean;
  includeMention?: boolean;
  includeToolbar?: boolean;
  showMoreButton?: boolean;
  mentionTypes?: ('regular_user' | 'freelancer' | 'contact' | 'group')[];
  onChange?: any;
  initialValue?: string | undefined;
  scrollingContainer?: string | HTMLElement | undefined;
  onImageUploader?: (upload: UploadFileApi) => Promise<void | string>;
  onLoadingImage?: (callback: (prev: number) => number) => void;
  onShortEnterKey?: (range: any, context: any) => void;
  onImageModalChanged?: (open: boolean) => void;
  style?: CSSProperties;
}

export interface RichTextEditorHandle {
  updateValue(text: string): void;
  getValue(): string | undefined;
  getYjs(): string;
  focus(): void;
  setMentionContents(mention: { id: number; name: string }): void;
  isEmpty(): boolean;
}

export const editorStatusColor: Record<string, TextProps['type']> = {
  connecting: 'warning',
  saving: 'warning',
  saved: 'success',
  disconnected: 'danger',
};

const mentionGroupOrder = ['regular_user', 'contact', 'group'];

const debounceGetMention = debounceAsync<AxiosResponse>((projectId: string | undefined, searchTerm: string) => {
  const url = projectId
    ? `${apiRoutes.BASE_URL}/projects/${projectId}/mentionables`
    : `${apiRoutes.BASE_URL}/mentions/mentionables`;

  return apiRequests.get(url, {
    search_term: searchTerm || undefined,
  });
}, 400);

const RichTextEditor = forwardRef<RichTextEditorHandle, RichTextEditorProps>(
  (
    {
      id,
      className = '',
      wrapperClassName,
      initialValue,
      projectId,
      placeholder,
      scrollingContainer = '.page-scrollbar',
      collaborativeEditor,
      mentionTypes,
      readonly = false,
      noContainer = false,
      containerFullHeight = false,
      includeMention = false,
      includeToolbar = true,
      showMoreButton = false,
      onImageUploader,
      onChange,
      onLoadingImage,
      onShortEnterKey,
      onImageModalChanged,
      style,
    },
    ref,
  ) => {
    const projectRef = useRef(projectId);
    const modulesRef = useRef<any>();
    const mentionTypesRef = useRef(mentionTypes);
    const includeMentionRef = useRef(includeMention);
    const onShortEnterKeyRef = useRef(onShortEnterKey);
    const readOnlyRef = useRef<HTMLDivElement>(null);
    const quillRef = useRef<ReactQuill>(null);

    const [value, setValue] = useState(() => {
      if (collaborativeEditor) {
        return undefined;
      }

      return initialValue?.endsWith('<p><br></p>') ? `${initialValue}<p><br></p>` : initialValue;
    });
    const [openLightbox, setOpenLightbox] = useState(false);
    const [listLightbox, setListLightbox] = useState<LightboxProps['slides']>([]);
    const [indexLightbox, setIndexLightbox] = useState(0);
    const [showAll, setShowAll] = useState(false);
    const [hasLargeContent, setHasLargeContent] = useState(true);

    const containerEditorEl = quillRef.current?.getEditingArea();

    const editorAreaEl = useMemo(() => {
      return containerEditorEl?.querySelector('.ql-editor');
    }, [containerEditorEl]);

    const containerEditorHeight = containerEditorEl?.clientHeight;

    const editorHeight = editorAreaEl?.clientHeight;

    const { yDoc } = useCollaborativeEditor({ collaborativeEditor, quillRef, initialValue });

    if (projectRef.current !== projectId) {
      projectRef.current = projectId;
    }

    if (mentionTypesRef.current !== mentionTypes) {
      mentionTypesRef.current = mentionTypes;
    }

    if (includeMentionRef.current !== includeMention) {
      includeMentionRef.current = includeMention;
    }

    if (onShortEnterKeyRef.current !== onShortEnterKey) {
      onShortEnterKeyRef.current = onShortEnterKey;
    }

    if (!modulesRef.current && !readonly) {
      modulesRef.current = {
        autoLinks: true,
        customCursors: !!collaborativeEditor,
        history: {
          userOnly: true,
        },
        keyboard: onShortEnterKey
          ? {
              bindings: {
                tab: {
                  key: 13,
                  shortKey: true,
                  handler: (range: any, context: any) => onShortEnterKeyRef.current?.(range, context),
                },
              },
            }
          : undefined,
        toolbar: includeToolbar
          ? [
              [{ size: ['small', false, 'large', 'huge'] }],
              ['bold', 'italic', 'underline', 'strike'],
              ['code-block'],
              ['link', 'image'],
              [{ list: 'ordered' }, { list: 'bullet' }],

              [{ color: [] }, { background: [] }],
              ['clean'],
            ]
          : undefined,
        mention: includeMention
          ? {
              allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
              dataAttributes: ['id', 'value', 'disabled', 'isTitle'],
              defaultMenuOrientation: 'top',
              mentionDenotationChars: ['@'],
              source: async (searchTerm: string, renderList: (list: []) => void) => {
                if (!includeMentionRef.current) {
                  renderList([]);
                  return;
                }

                try {
                  const res = await debounceGetMention(projectRef.current, searchTerm);

                  const list: any[] = (
                    mentionTypesRef.current
                      ? res.data.data.filter((item: any) => mentionTypesRef.current?.includes(item.type))
                      : res.data.data
                  ).map((el: any) => ({
                    value: el.name,
                    id: el.uuid,
                    type: el.type,
                  }));

                  list.sort((a, b) => {
                    const typeA = mentionGroupOrder.indexOf(a.type === 'freelancer' ? 'regular_user' : a.type);
                    const typeB = mentionGroupOrder.indexOf(b.type === 'freelancer' ? 'regular_user' : b.type);

                    return typeA - typeB;
                  });

                  if (
                    !mentionTypesRef.current ||
                    mentionTypesRef.current.includes('regular_user') ||
                    mentionTypesRef.current.includes('freelancer')
                  ) {
                    const firstUserIndex = list.findIndex((x) => x.type === 'regular_user');

                    if (firstUserIndex >= 0) {
                      list.splice(firstUserIndex, 0, {
                        value: 'Users',
                        disabled: true,
                        isTitle: true,
                      });
                    }
                  }

                  if (!mentionTypesRef.current || mentionTypesRef.current.includes('contact')) {
                    const firstContactIndex = list.findIndex((x) => x.type === 'contact');

                    if (firstContactIndex >= 0) {
                      list.splice(firstContactIndex, 0, {
                        value: 'Contacts',
                        disabled: true,
                        isTitle: true,
                      });
                    }
                  }

                  if (!mentionTypesRef.current || mentionTypesRef.current.includes('group')) {
                    const firstGroupIndex = list.findIndex((x) => x.type === 'group');

                    if (firstGroupIndex >= 0) {
                      list.splice(firstGroupIndex, 0, {
                        value: 'Groups',
                        disabled: true,
                        isTitle: true,
                      });
                    }
                  }

                  renderList(list as []);
                } catch (error) {
                  renderList([]);
                  asyncErrorHandler(error);
                }
              },
            }
          : undefined,
        imageUploader: {
          upload: async (file: File) => {
            if (!/^image\//.test(file.type)) {
              throw new Error('You could only upload images.');
            }

            if (onLoadingImage) {
              onLoadingImage((prev) => prev + 1);
            }

            try {
              const response = await apiRequests.uploadFile({ file, type: 'in-message' });

              if (onImageUploader) {
                const newUrl = await onImageUploader({
                  uid: response.uuid,
                  name: file.name,
                  status: 'success',
                  url: response.url,
                  size: file.size,
                  lastModified: file.lastModified,
                  lastModifiedDate: new Date(file.lastModified),
                  type: file.type,
                  percent: 100,
                  originFileObj: {
                    ...file,
                    lastModifiedDate: new Date(file.lastModified),
                    uid: response.uuid,
                  },
                  response,
                });

                if (newUrl) {
                  return newUrl;
                }
              }

              return response.url;
            } finally {
              onLoadingImage?.((prev) => prev - 1);
            }
          },
        },
      };
    }

    useImperativeHandle(ref, () => ({
      updateValue(text: string) {
        setValue(text);
      },

      getValue(): string | undefined {
        return value;
      },

      getYjs(): string {
        if (!yDoc) {
          return '';
        }

        return fromUint8Array(Y.encodeStateAsUpdate(yDoc));
      },

      focus() {
        quillRef.current?.focus();
      },

      isEmpty() {
        if (!quillRef.current) {
          return false;
        }

        const contents = quillRef.current.getEditor().getContents().ops;

        return !contents || !contents.find((x) => typeof x.insert !== 'string' || !!x.insert.trim());
      },

      setMentionContents({ id: mentionId, name }: { id: number; name: string }) {
        const editor = quillRef.current?.editor;

        if (editor && quillRef.current) {
          editor.setContents([
            {
              insert: {
                mention: {
                  id: mentionId,
                  value: name,
                  denotationChar: '@',
                },
              },
            },
            {
              insert: ' ',
            },
          ] as any);

          quillRef.current.value = editor.root.innerHTML;

          quillRef.current.setEditorSelection(editor, { index: 2, length: 0 });
        }
      },
    }));

    useEffect(() => {
      const editor = quillRef.current?.getEditor();

      if (!containerEditorEl || !editor) {
        return () => {};
      }

      const openLightboxHandler = (event: Event) => {
        const element = event.target;

        if (isImgElement(element) && editor) {
          const { ops } = editor.getContents();

          const listImages: LightboxProps['slides'] = [];

          ops?.forEach((delta) => {
            if (delta.insert.image) {
              listImages.push({
                src: delta.insert.image,
              });
            }

            if (element.src === delta.insert.image) {
              setIndexLightbox(listImages.length - 1);
            }
          });

          onImageModalChanged?.(true);

          setListLightbox(listImages);

          setOpenLightbox(true);
        }
      };

      containerEditorEl.addEventListener('click', openLightboxHandler);

      const focusHandler = (event: Event) => {
        if (event.target !== containerEditorEl || !quillRef.current) {
          return;
        }

        editor.setSelection(editor.getLength(), 0);
      };

      containerEditorEl.addEventListener('click', focusHandler);

      return () => {
        containerEditorEl.removeEventListener('click', openLightboxHandler);
        containerEditorEl.removeEventListener('click', focusHandler);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [containerEditorEl]);

    useEffect(() => {
      const container = readOnlyRef.current;

      if (!container) return () => {};

      const openLightboxHandler = (event: Event) => {
        const element = event.target;

        if (isImgElement(element)) {
          const images = container.querySelectorAll('img');
          const listImages: LightboxProps['slides'] = [];

          images?.forEach((img) => {
            if (img.src) {
              listImages.push({
                src: img.src,
              });
            }

            if (element.src === img.src) {
              setIndexLightbox(listImages.length - 1);
            }
          });

          onImageModalChanged?.(true);

          setListLightbox(listImages);

          setOpenLightbox(true);
        }
      };

      container.addEventListener('click', openLightboxHandler);

      return () => {
        container.removeEventListener('click', openLightboxHandler);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [readOnlyRef]);

    useEffect(() => {
      if (showMoreButton && hasLargeContent && !showAll && quillRef.current?.editor?.hasFocus()) {
        setShowAll(true);
      }
    }, [showMoreButton, hasLargeContent, showAll, value]);

    useLayoutEffect(() => {
      if (!showMoreButton || !containerEditorHeight || !editorHeight) return;

      if (editorHeight >= containerEditorHeight) {
        if (!hasLargeContent) {
          setHasLargeContent(true);
        }

        return;
      }

      if (hasLargeContent) {
        setHasLargeContent(false);
      }
    }, [hasLargeContent, showAll, showMoreButton, editorHeight, containerEditorHeight]);

    return (
      <>
        {!readonly ? (
          <div className={wrapperClassName} style={{ position: 'relative' }}>
            <ReactQuill
              id={id}
              ref={quillRef}
              theme="snow"
              scrollingContainer={scrollingContainer}
              className={`${className}${containerFullHeight ? ' quill-container-h-full' : ''}${
                !includeToolbar ? ' no-toolbar' : ''
              }${noContainer ? ' no-container' : ''}${showMoreButton ? ' quill-has-more-button' : ''}${
                showMoreButton && !showAll && hasLargeContent ? ' quill-reduced-size' : ''
              }`}
              modules={modulesRef.current}
              placeholder={placeholder}
              value={value}
              onChange={(newValue) => {
                setValue(newValue);
                if (onChange) {
                  onChange(newValue || null);
                }
              }}
              style={style}
            />

            {showMoreButton && hasLargeContent && (
              <div className={`quill-content-show${!showAll ? ' quill-content-show--more' : ''}`}>
                <Button type="link" onClick={() => setShowAll(!showAll)}>
                  {!showAll ? 'Show more' : 'Show less'}
                </Button>
              </div>
            )}
          </div>
        ) : (
          <div ref={readOnlyRef} className={`quill ${className} ${noContainer ? 'no-container ' : ''}`} style={style}>
            <div className="ql-container ql-snow">
              <div
                className="ql-editor"
                dangerouslySetInnerHTML={{
                  __html: value || '',
                }}
              />
            </div>
          </div>
        )}

        <Lightbox
          open={openLightbox}
          index={indexLightbox}
          close={() => {
            onImageModalChanged?.(false);
            setOpenLightbox(false);
          }}
          plugins={[Counter as any, Thumbnails, Zoom]}
          // @ts-ignore
          counter={{ container: { style: { top: 'unset', bottom: 0 } } }}
          zoom={{ maxZoomPixelRatio: 4 }}
          carousel={{ finite: true }}
          slides={listLightbox}
        />
      </>
    );
  },
);

RichTextEditor.displayName = 'RichTextEditor';

export default RichTextEditor;
