import { AxiosResponse } from 'axios';
import apiRoutes from 'config/apiRoute';
import { call, put, StrictEffect, takeEvery } from 'redux-saga/effects';
import api from 'utils/api';
import asyncErrorHandler from 'utils/asyncErrorHandler';

import { getCustomers, setCustomersData, setCustomersError, setCustomersLoading } from './customers-slice';

const { get } = api;

function* getCustomersSagaListener(action: any) {
  try {
    yield put(setCustomersLoading());
    const res: AxiosResponse = yield call(get, apiRoutes.CUSTOMERS, action.payload);
    yield put(setCustomersData(res.data));
  } catch (error) {
    yield put(setCustomersError(error));
    asyncErrorHandler(error);
  }
}

function* customerSaga(): Generator<StrictEffect> {
  yield takeEvery(getCustomers.type, getCustomersSagaListener);
}

export default customerSaga;
