import { trimUrl } from 'utils/string.utils';

const ENVIRONMENT = {
  CLIENT_URL: process.env.REACT_APP_CLIENT_URL,
  BASE_URL: process.env.REACT_APP_API_URL || 'https://api-dev.lionshare.app/api',
  NODE_ENV: process.env.NODE_ENV,
  isDevelopment: process.env.NODE_ENV === 'development',
  REACT_APP_CALENDLY_LINK: process.env.REACT_APP_CALENDLY_LINK ?? 'https://calendly.com/yvikas9290/test',
  REACT_APP_GOOGLE_CLIENT_ID:
    process.env.REACT_APP_GOOGLE_CLIENT_ID ?? '1008943756583-91kmjtaii8fectk50on1kbtsjrot76pk.apps.googleusercontent.com',
  REACT_APP_MAINTENANCE: process.env.REACT_APP_MAINTENANCE === 'yes',
  REACT_APP_VERSION: '1.0.53',
  REACT_APP_UPLOADS_PATH:
    trimUrl(process.env.REACT_APP_UPLOADS_PATH) ?? 'https://lionshare-dev.s3.us-west-2.amazonaws.com/uploads',
  IS_DEV_ENV: !process.env.REACT_APP_API_URL || process.env.REACT_APP_API_URL?.includes('api-dev'),
  REACT_APP_PUSHER_CLUSTER: (process.env.REACT_APP_PUSHER_CLUSTER ?? '') as string,
  REACT_APP_PUSHER_KEY: (process.env.REACT_APP_PUSHER_KEY ?? '') as string,
  REACT_APP_SENTRY_DNS: process.env.REACT_APP_SENTRY_DNS,
  REACT_APP_SENTRY_ENVIRONMENT: process.env.REACT_APP_SENTRY_ENVIRONMENT ?? 'local',
  REACT_APP_SENTRY_RELEASE: process.env.REACT_APP_SENTRY_RELEASE,
  REACT_APP_YJS_WEBSOCKET: process.env.REACT_APP_YJS_WEBSOCKET ?? 'ws://127.0.0.1:5050',
  REACT_APP_RADAR_KEY: process.env.REACT_APP_RADAR_KEY,
};

export default ENVIRONMENT;
