import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaRegCopy } from 'react-icons/fa';
import { Col, Row, Tag, Typography, Popconfirm, Tooltip } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import { convertMinsToHrsMins, getStatusColor, parseDateToUSFormat } from 'utils/string.utils';
import appRoutes from 'config/appRoutes';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import { getRequestStatusLabel } from '../../config/getRequestStatusOptions';
import style from './index.module.less';

const { Text, Title } = Typography;

interface RequestListItemProps {
  item: any;
  to: string;
  showClient?: boolean;
  showHours?: boolean;
  onRemove?: (item: any) => Promise<void>;
}

const RequestListItem: FC<RequestListItemProps> = ({ item, to, showClient = false, showHours = false, onRemove }) => {
  const [removing, setRemoving] = useState(false);

  const { md, lg } = useBreakpoint();

  const isCompleted = item.status === 'completed';

  const clientName = item.project?.customer?.business_name ?? 'No client';

  let date: string;

  if (isCompleted) {
    date = item.completed_at ? parseDateToUSFormat(item.completed_at) : 'no completed date';
  } else {
    date = item.target_date ? parseDateToUSFormat(item.target_date, false, true) : 'no due date';
  }

  let hours: string;

  if (isCompleted) {
    hours = `${convertMinsToHrsMins(item.time_spent ?? 0)} hours`;
  } else {
    hours =
      item.estimated_min || item.estimated_max
        ? `${convertMinsToHrsMins(item.estimated_min ?? 0)} - ${convertMinsToHrsMins(item.estimated_max ?? 0)} hours`
        : 'No estimated time';
  }

  return (
    <Link to={to} className={style.link}>
      <Row className={style.rowItem} gutter={[12, 0]}>
        <Col flex="auto" className="w-full lg-w-auto" style={{ overflow: 'hidden' }}>
          <div className={style.subTitle}>{item.title}</div>

          <Title level={5} className={style.title} ellipsis>
            {md ? item.subject : <Tooltip title={item.subject}>{item.subject}</Tooltip>}
          </Title>
        </Col>

        <Col flex="none" className="w-full lg-w-auto">
          <Row className={style.rowExtraInfo} gutter={[12, 0]}>
            <Col className={style.colProject}>
              <div className={style.subTitle}>by {item.requester?.name ?? 'No user'}</div>

              {showClient ? (
                <Text ellipsis>{lg ? clientName : <Tooltip title={clientName}>{clientName}</Tooltip>}</Text>
              ) : (
                <div>
                  {isCompleted ? 'Completed' : 'Delivery'}: {date}
                </div>
              )}
            </Col>

            {showHours && (
              <Col className={style.colHours}>
                <div className={style.subTitle}>{isCompleted ? 'Spent' : 'Estimated'}</div>

                <div>{hours}</div>
              </Col>
            )}

            <Col className={style.colStatus}>
              <div style={{ display: 'flex', alignItems: 'center', gap: 6, justifyContent: 'right' }}>
                <Tag style={{ marginRight: 0 }} color={getStatusColor(item.status)}>
                  {getRequestStatusLabel(item.status)}
                </Tag>

                {!onRemove ? (
                  <Link to={`${appRoutes.REQUESTS}/${item.uuid}/duplicate`}>
                    <FaRegCopy />
                  </Link>
                ) : undefined}
              </div>

              <Row justify="end" align="middle" wrap={false} gutter={[8, 0]}>
                {showClient && (
                  <Col>
                    <Tooltip title={isCompleted ? 'Completed' : 'Delivery'}>{date}</Tooltip>
                  </Col>
                )}

                {onRemove ? (
                  <Col style={{ lineHeight: 0 }}>
                    <Link to={`${appRoutes.REQUESTS}/${item.uuid}/duplicate`}>
                      <FaRegCopy />
                    </Link>
                  </Col>
                ) : undefined}

                {onRemove ? (
                  <Col style={{ textAlign: 'right', lineHeight: 0 }}>
                    <Popconfirm
                      disabled={removing}
                      title="Do you want to remove this request from the task?"
                      onConfirm={(event) => {
                        event?.stopPropagation();
                        setRemoving(true);
                        onRemove(item).finally(() => setRemoving(false));
                      }}
                      onCancel={(event) => event?.stopPropagation()}
                    >
                      <CloseCircleOutlined className="red pointer" />
                    </Popconfirm>
                  </Col>
                ) : undefined}
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Link>
  );
};

export default RequestListItem;
