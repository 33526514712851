import { FC, useState } from 'react';
import { CaretRightOutlined, PauseOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';
import { convertDaysHrsMinsToMins, convertMinsToHrsMins } from 'utils/string.utils';
import useTimer from './useTimer';
import styles from './Timer.module.less';
import TimerContainer from './TimerContainer';

interface TimerDropdownProps {
  task_id?: string;
  projectId?: string;
  globalActiveTimer?: boolean;
  timerContent?: boolean;
  taskStatus?: string;
  needsAlert?: boolean;
  showUserSelection?: boolean;
  onAddEntry?: any;
}

const TimerDropdown: FC<TimerDropdownProps> = ({
  task_id,
  projectId,
  globalActiveTimer = false,
  timerContent,
  taskStatus,
  needsAlert,
  showUserSelection,
  onAddEntry,
}) => {
  const [visible, setVisible] = useState(false);

  const {
    minutes,
    hours,
    days,
    isRunning,
    current,
    totalTime,
    form,
    loading,
    loadingTotal,
    saving,
    onChangeTimer,
    startTimer,
    pauseTimer,
    updateTimer,
    resetTimer,
    addEntry,
  } = useTimer(task_id, globalActiveTimer, timerContent, onAddEntry, () => setVisible(false));

  if (globalActiveTimer && !current) {
    return null;
  }

  return (
    <>
      {visible && (
        <div
          className={styles.modal}
          onClick={(e) => {
            e.stopPropagation();
            setVisible(false);
            if (!saving) {
              updateTimer();
            }
          }}
        />
      )}
      <Dropdown
        overlay={
          <TimerContainer
            style={{ width: 480 }}
            dataLog={current}
            totalTime={totalTime.userTotal}
            form={form}
            projectId={projectId}
            taskStatus={taskStatus}
            needsAlert={needsAlert}
            loading={loading || saving}
            loadingTotal={loadingTotal}
            globalActiveTimer={globalActiveTimer}
            showUserSelection={showUserSelection}
            onAddEntry={addEntry}
            onChangeTimer={onChangeTimer}
            onResetTimer={resetTimer}
            onCancel={() => {
              setVisible(false);
              updateTimer();
            }}
          />
        }
        placement="bottomRight"
        visible={visible}
      >
        <div
          className={`${styles.timer} ${saving || loading ? styles.saving : ''}
				${globalActiveTimer ? styles.global : ''}`}
        >
          {isRunning ? <PauseOutlined onClick={pauseTimer} /> : <CaretRightOutlined onClick={startTimer} />}&nbsp;
          <span
            className={`${styles.value} ${isRunning ? styles.running : ''}`}
            onClick={timerContent ? undefined : () => setVisible(true)}
          >
            {convertMinsToHrsMins(convertDaysHrsMinsToMins(days, hours, minutes))}
            {!globalActiveTimer && !timerContent && <> &bull; {convertMinsToHrsMins(totalTime.taskTotal)}</>}
          </span>
        </div>
      </Dropdown>
    </>
  );
};

export default TimerDropdown;
