/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-undef */
/* eslint-disable no-constant-condition */

import apiRoutes from 'config/apiRoute';
import { call, put, takeEvery } from 'redux-saga/effects';
import api from 'utils/api';
import asyncErrorHandler from 'utils/asyncErrorHandler';
import { GET_ANSWERS, getAnswersFullfilled, getAnswersPending, getAnswersRejected } from '../actions';

/**
 * worker saga
 */
const { get } = api;
export function* getAnswersWorker(action: any): any {
  try {
    yield put(getAnswersPending());
    const response = yield call(get, apiRoutes.ANSWERS, action.payload);
    yield put(getAnswersFullfilled(response.data));
  } catch (e) {
    yield put(getAnswersRejected(e));
    asyncErrorHandler(e);
  }
}

/**
 * watcher saga
 */
export default function* getAnswersWatcher() {
  yield takeEvery(GET_ANSWERS, getAnswersWorker);
}
