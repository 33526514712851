import { Result } from 'antd';

const Maintenance = () => {
  return (
    <Result
      status="500"
      subTitle="I’m sure you are sad because you can’t login, but don’t worry.
  We’ll be back online shortly with some awesome new updates

  Hang tight"
      title="Under Maintenance"
    />
  );
};

export default Maintenance;
