import { FC } from 'react';
import { Form } from 'antd';
import SelectSearch from 'components/SelectSearch';
import apiRoutes from 'config/apiRoute';

interface ContentCategoryFilterProps {
  filter: any;
  handleSetFilter: any;
}

const ContentCategoryFilter: FC<ContentCategoryFilterProps> = ({ filter, handleSetFilter }) => {
  return (
    <Form initialValues={{ categories: filter?.filter((el: any) => el.isCategory)?.map((el: any) => el.uuid) }}>
      <SelectSearch
        api={apiRoutes.POST_CATEGORIES}
        defaultParams={{
          'page[size]': 100,
          'page[number]': 1,
        }}
        formItemClassName="mb-0"
        initialOptions={[...filter?.filter((el: any) => el.isCategory)]}
        mode="multiple"
        name="categories"
        onChange={(values: any) => {
          const newArr = [
            ...filter?.filter((el: any) => !el.isCategory),
            ...values?.map((el: any) => ({
              ...el,
              filterBy: 'category.uuid',
              value: el?.uuid,
              isCategory: true,
            })),
          ];
          handleSetFilter(newArr);
        }}
        placeholder="Categories"
        style={{ width: '100%', minWidth: '150px', textAlign: 'left' }}
        size="large"
      />
    </Form>
  );
};

export default ContentCategoryFilter;
