import { ReactNode, Context, useContext } from 'react';
import { Empty, Skeleton } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { InfinityScrollContext } from './createInfinityScrollApiContext';

interface InfiniteScrollApiProps<T extends Record<string, unknown>> {
  context: Context<InfinityScrollContext<T>>;
  scrollableTarget: string;
  children: ReactNode;
}

const InfiniteScrollApi = <T extends Record<string, unknown>>({
  context,
  scrollableTarget,
  children,
}: InfiniteScrollApiProps<T>) => {
  const { items, hasMore, fetchMoreItems } = useContext(context);

  return (
    <InfiniteScroll
      scrollableTarget={scrollableTarget}
      dataLength={items.length}
      next={fetchMoreItems}
      hasMore={hasMore}
      loader={<Skeleton active />}
      endMessage={!items.length ? <Empty style={{ padding: '16px 0' }} description="No Notifications" /> : undefined}
    >
      {children}
    </InfiniteScroll>
  );
};

export default InfiniteScrollApi;
