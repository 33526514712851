import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { Layout as AntLayout } from 'antd';
import ActionBarLayout from 'components/ActionbarLayout';
import GlobalTaskModal from './components/GlobalTaskModal';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import { getAllRoutes } from './components/Sidebar/config';
import useCountUnreadInbox from './useCountUnreadInbox';
import style from './layout.module.less';
import AppHeader from '../AppHeader';

interface AppLayoutProps {
  overflowScroll?: boolean;
}

const Layout: FC<AppLayoutProps> = ({ overflowScroll, children }) => {
  const { pathname } = useLocation();
  const pathConfig = getAllRoutes();
  const activeRoute = pathConfig.find(
    (el) => el.path && ((el.path === '/' && el.path === pathname) || (el.path !== '/' && pathname.startsWith(el.path))),
  );

  useCountUnreadInbox();

  return (
    <AntLayout className={style.layout}>
      <GlobalTaskModal />

      <Header activeRoute={activeRoute} />

      <AppHeader activeRoute={activeRoute} />

      <AntLayout>
        <Sidebar activeRoute={activeRoute} />

        <ActionBarLayout overflowScroll={overflowScroll}>{children}</ActionBarLayout>
      </AntLayout>
    </AntLayout>
  );
};

export default Layout;
