import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getWeeklyLogs } from 'store/container/weeklyLogs/weeklyLogs-slice';

const useWeeklyTimer = () => {
  const user = useSelector((store: any) => store.auth.user);
  const globalLog = useSelector((globalState: any) => globalState.globalLog);
  const activeLogs = useSelector((globalState: any) => globalState.activeLogs);
  const weeklyLogs = useSelector((globalState: any) => globalState.weeklyLogs);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getWeeklyLogs());
  }, [user.uuid, globalLog.trigger, activeLogs.data, dispatch]);

  return { weeklyLogs };
};

export default useWeeklyTimer;
