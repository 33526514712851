import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

const useUrlSearchParams = () => {
  const { search } = useLocation();

  return useMemo(() => {
    return new URLSearchParams(search);
  }, [search]);
};

export default useUrlSearchParams;
