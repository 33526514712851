import { AxiosResponse } from 'axios';
import apiRoutes from 'config/apiRoute';
import { call, put, StrictEffect, takeEvery } from 'redux-saga/effects';
import api from 'utils/api';
import asyncErrorHandler from 'utils/asyncErrorHandler';

import { getEmailLogs, setEmailLogsData, setEmailLogsError, setEmailLogsLoading } from './email-logs-slice';

const { get } = api;

function* getEmailLogsSagaListener(action: any) {
  try {
    yield put(setEmailLogsLoading());
    const res: AxiosResponse = yield call(get, apiRoutes.EMAIL_LOGS, action.payload);
    yield put(setEmailLogsData(res.data));
  } catch (error) {
    yield put(setEmailLogsError(error));
    asyncErrorHandler(error);
  }
}

function* emailLogsSaga(): Generator<StrictEffect> {
  yield takeEvery(getEmailLogs.type, getEmailLogsSagaListener);
}

export default emailLogsSaga;
