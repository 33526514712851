import { AppTableInitialState, createAppTableStore } from 'components/AppTable/store';
import apiRoutes from 'config/apiRoute';
import merge from 'lodash/merge';
import { PartialDeep } from 'type-fest';

export default function storeTableTaskFactory<
  T extends PartialDeep<AppTableInitialState> & Record<string, any>,
  Name extends string = string,
>({ name, initialState, onlyTable }: { name: Name; initialState?: T; onlyTable: boolean }) {
  const defaultState: PartialDeep<AppTableInitialState> = {
    params: {
      filters: {
        task_status: [
          { value: 'todo' },
          { value: 'in_progress' },
          { value: 'waiting_approval' },
          { value: 'working_on_now' },
          { value: 'pending' },
          { value: 'approved' },
          { value: 'needs_attention' },
        ],
      },
      sort: { column: 'task_created_at', order: 'descend' },
    },
    paramFilterFields: {
      tags: 'filters[tags][]',
      task_status: 'filters[task_status][]',
      related_users: 'filters[related_users.uuid][]',
      creators: 'filters[creator.uuid][]',
      clientTasks: 'filters[requester_type][]',
      blueprint: 'filters[project_status][]',
      overdue: 'filters[overdue_only][]',
      shared: 'filters[related_users.uuid][]',
      taskActiveMaster: 'filters[seed.uuid][]',
    },
    searchFilters: {
      created_at_start: { rule: 'greater_than_or_equals', field: 'task_created_at', index: 1 },
      created_at_end: { rule: 'less_than_or_equals', field: 'task_created_at', index: 2 },
      due_at_start: { rule: 'greater_than_or_equals', field: 'task_end_date', index: 3 },
      due_at_end: { rule: 'less_than_or_equals', field: 'task_end_date', index: 4 },
    },
    searchField: '*',
    searchKey: 'search_term[0][0]',
  };

  if (!onlyTable) {
    defaultState.tabKey = 'table';
    defaultState.apiParamsHandler = (params, state) => {
      if (state.tabKey !== 'list') {
        params['page[size]'] = 1000;
        params['page[number]'] = 1;
      }

      if (state.tabKey === 'chart') {
        params['sort[by]'] = 'task_created_at';
        params['sort[direction]'] = 'desc';
      }

      if (state.tabKey === 'kanban') {
        const filterStatus = params['filters[task_status][]'] as string | string[];
        const removeStatus = ['completed', 'cancelled'];

        if (filterStatus && Array.isArray(filterStatus)) {
          params['filters[task_status][]'] = filterStatus.filter((status) => !removeStatus.includes(status));
        }

        params['sort[by]'] = 'task_sort';
        params['sort[direction]'] = 'asc';
      }

      return params;
    };
  }

  return createAppTableStore({
    name,
    url: apiRoutes.PROJECT_TASKS,
    initialState: merge(defaultState, initialState),
    transformData: (data) => {
      const removeSubtasks = (item: Record<string, any>) => {
        if (item.subtasks.length === 0) {
          delete item.subtasks;
        } else {
          item.subtasks = item.subtasks.map((task: any) => removeSubtasks(task));
        }

        return { ...item };
      };

      return data?.[0]?.subtasks ? data.map((item) => removeSubtasks(item)) : data;
    },
  });
}
