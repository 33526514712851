import { FC } from 'react';
import { Form } from 'antd';
import { useSelector } from 'react-redux';
import SelectSearch from 'components/SelectSearch';
import apiRoutes from 'config/apiRoute';

interface AssigneesFilterProps {
  handleSetFilter: any;
  filter: any;
  form: any;
}

const AssigneesFilter: FC<AssigneesFilterProps> = ({ filter, handleSetFilter, form }) => {
  const user = useSelector((store: any) => store.auth.user);
  const filterUsers = filter?.filter((el: any) => el.isAssignees) ?? [];

  return (
    <Form form={form} initialValues={{ contacts: filterUsers.map((el: any) => el.uuid) }}>
      <SelectSearch
        api={apiRoutes.ASSIGNEES}
        formItemClassName="mb-0"
        initialOptions={filterUsers}
        mode="multiple"
        name="contacts"
        valueKey="uuid"
        searchKey="search_term"
        searchByLabelKey
        onChange={(values: any) => {
          const uniqueUsers: any[] = [];
          const temp: any = {};
          values.forEach((el: any) => {
            const id = el.uuid === 'myTasks' ? user.uuid : el.uuid;

            if (!temp[id]) {
              uniqueUsers.push(el);
              temp[id] = true;
            }
          });

          handleSetFilter([
            ...filter?.filter((el: any) => !el.isAssignees),
            ...uniqueUsers.map((el: any) => ({
              ...el,
              filterBy: 'related_users.uuid',
              value: el.uuid === 'myTasks' ? user.uuid : el.uuid,
              isAssignees: true,
            })),
          ]);
        }}
        placeholder="Assignees"
        startingOptions={[
          { name: 'My tasks', uuid: 'myTasks' },
          { name: 'Unassigned', uuid: '_unassigned_' },
        ]}
        style={{ width: '100%', minWidth: '150px', textAlign: 'left' }}
        size="large"
        allowClear
      />
    </Form>
  );
};

export default AssigneesFilter;
