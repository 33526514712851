import { useEffect } from 'react';

import ENVIRONMENT from 'config/environment';
import storage from 'config/storage';

const useVersionControl = () => {
  useEffect(() => {
    const localVersion = localStorage.getItem(storage.version);
    const currentVersion = ENVIRONMENT.REACT_APP_VERSION as string;

    if (!localVersion) {
      localStorage.setItem(storage.version, currentVersion);
    } else if (localVersion !== currentVersion) {
      // Exec code
      Object.entries(localStorage).forEach((pair) => {
        if (pair[0].includes('--temp-refresh')) {
          localStorage.removeItem(pair[0]);
        }
      });
      localStorage.setItem(storage.version, currentVersion);
    }
  }, []);
};

export default useVersionControl;
