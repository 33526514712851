/* eslint-disable */
export const GET_ANSWERS = 'GET_ANSWERS';
export const GET_ANSWERS_PENDING = 'GET_ANSWERS_PENDING';
export const GET_ANSWERS_FULLFILLED = 'GET_ANSWERS_FULLFILLED';
export const GET_ANSWERS_REJECTED = 'GET_ANSWERS_REJECTED';

export const getAnswers = (payload = {}): any => ({
  type: GET_ANSWERS,
  payload,
});
export const getAnswersPending = (): any => ({
  type: GET_ANSWERS_PENDING,
});

export const getAnswersFullfilled = (data: any): any => ({
  type: GET_ANSWERS_FULLFILLED,
  data,
});

export const getAnswersRejected = (error: any) => ({
  type: GET_ANSWERS_REJECTED,
  error,
});
