export function getRequestStatusOptions(withDraft?: boolean) {
  return [
    ...(withDraft ? [{ label: 'Draft', value: 'draft' }] : []),
    { label: 'In preparation', value: 'new' },
    { label: 'Waiting Approval', value: 'waiting_approval' },
    { label: 'Approved', value: 'approved' },
    { label: 'Needs attention', value: 'needs_attention' },
    { label: 'In progress', value: 'in_progress' },
    { label: 'Completed', value: 'completed' },
    { label: 'Cancelled', value: 'cancelled' },
  ];
}

export function getRequestStatusLabel(value: string) {
  return getRequestStatusOptions(true).find((item) => item.value === value)?.label;
}
