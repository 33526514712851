import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import TaskModal from 'components/TaskModal';
import { RootState } from 'store';
import {
  setGlobalTaskModalClosedTrigger,
  setGlobalTaskModalSavedTrigger,
} from 'store/container/globalTaskModal/globalTaskModal-slice';
import useUrlSearchParams from 'utils/useUrlSearchParams';

const GlobalTaskModal = () => {
  const taskProject = useSelector((store: RootState) => store.globalTaskModal.project);

  const history = useHistory();

  const dispatch = useDispatch();

  const search = useUrlSearchParams();

  return (
    <TaskModal
      visible={search.get('task_uuid')}
      project={search.has('task_any_project') ? undefined : taskProject}
      highlightCommentUuid={search.get('task_comment_uuid') ?? undefined}
      createToParentUuid={search.get('task_parent_id') ?? undefined}
      createToStatus={search.get('task_status') ?? undefined}
      createFromTaskUuid={search.get('task_copy_uuid') ?? undefined}
      isPersonal={search.has('task_personal')}
      onSaved={() => dispatch(setGlobalTaskModalSavedTrigger())}
      onClose={() => {
        history.push(history.location.pathname);
        dispatch(setGlobalTaskModalClosedTrigger());
      }}
    />
  );
};

export default GlobalTaskModal;
