import { FC } from 'react';
import { useSelector } from 'react-redux';
import { AvatarSize } from 'antd/lib/avatar/SizeContext';
import UserAvatar from 'components/UserAvatar';

interface AuthUserAvatarProps {
  size?: AvatarSize | undefined;
}

const AuthUserAvatar: FC<AuthUserAvatarProps> = ({ size }) => {
  const currentUser = useSelector((store: any) => store.auth.user);

  return <UserAvatar user={currentUser} size={size} />;
};

export default AuthUserAvatar;
