import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Result } from 'antd';

const NotFound404: FC = () => (
  <Result
    extra={
      <Link className="ant-btn ant-btn-primary" to="/">
        Back Home
      </Link>
    }
    status="404"
    subTitle="Sorry, the page you visited does not exist."
    title="404"
  />
);

export default NotFound404;
