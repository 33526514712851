import { FC } from 'react';
import { Col, Form, Input, Modal } from 'antd';

const { TextArea } = Input;

interface FeedbackModalProps {
  open: boolean;
  loading: any;
  onConfirm: (data: Record<string, any>) => Promise<any>;
  onClose: () => void;
}

const FeedbackModal: FC<FeedbackModalProps> = ({ open, onConfirm, onClose, loading }) => {
  const [form] = Form.useForm();

  return (
    <Modal
      visible={open}
      confirmLoading={loading}
      onCancel={onClose}
      onOk={() => {
        form.validateFields().then((data) => {
          onConfirm({ ...data, approval_status: 'cancelled' }).then((response) => {
            if (response) form.resetFields();
          });
        });
      }}
      destroyOnClose
    >
      <Form layout="vertical" form={form}>
        <Col xs={24}>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'This field is required',
              },
            ]}
            className="mb-0"
            label="Comment"
            name="approval_feedback"
          >
            <TextArea placeholder="Please provide a feedback about the modifications" rows={5} />
          </Form.Item>
        </Col>
      </Form>
    </Modal>
  );
};

export default FeedbackModal;
