/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import { Card, Col, Form, Row, Select, Space, Spin, Typography } from 'antd';
import uniqBy from 'lodash/uniqBy';
import apiRequests from 'utils/api';
import asyncErrorHandler from 'utils/asyncErrorHandler';
import { LoadingOutlined, UserAddOutlined } from '@ant-design/icons';
import { checkIsEmail } from 'utils/string.utils';

const { Text } = Typography;
interface InviteContactSelectSearchProps {
  api: string;
  labelKeys?: string[];
  valueKey?: string;
  name?: string;
  label?: string;
  rules?: any;
  className?: any;
  initialOptions?: any[];
  startingOptions?: any[];
  initialOption?: any;
  defaultFilter?: any;
  labelCol?: any;
  mode?: any;
  style?: any;
  formItemClassName?: string;
  placeholder?: string;
  render?: any;
  onChange?: any;
  defaultParams?: any;
  size?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  onLoad?: any;
  allowClear?: boolean;
  onInviteSelect?: any;
}

const InviteContactSelectSearch: FC<InviteContactSelectSearchProps> = ({
  labelKeys = ['name'],
  valueKey = 'uuid',
  name,
  label,
  rules,
  api,
  className,
  initialOptions = [],
  initialOption,
  defaultFilter,
  labelCol,
  mode,
  style,
  formItemClassName,
  startingOptions = [],
  placeholder,
  render,
  size,
  onChange,
  defaultParams = {},
  disabled,
  autoFocus,
  onLoad,
  allowClear,
  onInviteSelect,
}) => {
  const [open, setOpen] = useState(undefined as any);
  const singleOption = [];
  if (initialOption) {
    singleOption.push(initialOption);
  }

  const [state, setState] = useState({
    loading: false,
    searching: false,
    data: [
      ...[...startingOptions, ...singleOption, ...initialOptions].map((el) => ({
        ...el,
        value_copy: el?.value_copy ?? el?.value,
        label: el[labelKeys[0]],
        value: el[valueKey],
      })),
    ] as any,
    search: null as any,
  });
  const { data, loading } = state;
  const [search, setSearch] = useState<string>();

  const searchTrim = search?.trim();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setState((prevState) => ({ ...prevState, loading: true }));
        const params: any = { ...defaultParams };

        if (searchTrim) {
          labelKeys?.forEach((val, i) => {
            params[`search_term[${i}][field]`] = val;
            params[`search_term[${i}][rule]`] = 'contains';
            params[`search_term[${i}][value]`] = searchTrim;
          });
        }

        if (defaultFilter && defaultFilter?.filterBy && defaultFilter?.value) {
          params[`filters[${defaultFilter?.filterBy}][]`] = defaultFilter?.value;
        }

        const res = await apiRequests.get(api, params);

        const getUniqData = (prevState: any) => {
          const newData = res?.data?.data?.map((el: any) => {
            let parsedLabel = '';
            labelKeys?.forEach((la) => {
              parsedLabel += `${el[la]} `;
            });

            return {
              ...el,
              value_copy: el?.value_copy ?? el?.value,
              label: parsedLabel,
              value: el[valueKey],
            };
          });
          const uniqData = uniqBy(
            [
              ...startingOptions.map((el) => {
                let parsedLabel = '';
                labelKeys?.forEach((la) => {
                  parsedLabel += `${el[la]} `;
                });

                return {
                  ...el,
                  value_copy: el?.value_copy ?? el?.value,
                  label: parsedLabel,
                  value: el[valueKey],
                };
              }),
              ...newData,
              ...prevState.data,
            ],
            'value',
          );

          if (onLoad) {
            onLoad(uniqData);
          }

          return uniqData;
        };

        setState((prevState) => ({
          ...prevState,
          loading: false,
          data: getUniqData(prevState),
        }));
      } catch (error) {
        asyncErrorHandler(error);
        setState((prevState) => ({ ...prevState, loading: false }));
      }
    };
    fetchData();
  }, [searchTrim, defaultFilter?.filterBy, defaultFilter?.value]);

  return (
    <Form.Item
      style={{
        // @ts-ignore
        position: 'relative !important',
      }}
      className={formItemClassName}
      label={label}
      labelCol={labelCol}
      name={name}
      rules={rules}
    >
      <Select
        onClick={() => {
          if (open === false) {
            setOpen(undefined);
          }
        }}
        open={open}
        allowClear={allowClear}
        dropdownStyle={{ position: 'fixed' }}
        className={className}
        disabled={disabled}
        filterOption={(input, el: any) => el?.label?.toLowerCase().includes(input?.toLowerCase())}
        loading={loading}
        maxTagCount="responsive"
        autoFocus={autoFocus}
        notFoundContent={
          loading ? (
            <Row style={{ margin: '30px 0' }} gutter={16} justify="center">
              <Col>
                <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
              </Col>
              <Col>
                <Text>Fetching the data...</Text>
              </Col>
            </Row>
          ) : !loading && checkIsEmail(searchTrim) ? (
            <div
              onClick={
                onInviteSelect
                  ? () => {
                      onInviteSelect(searchTrim);
                      setOpen(false);
                    }
                  : undefined
              }
              className="pointer"
            >
              <Card bodyStyle={{ padding: '10px 20px', background: '#eff8fb40' }}>
                <Space size={20}>
                  <UserAddOutlined style={{ fontSize: '180%' }} />
                  <Space size={0} direction="vertical">
                    <Text strong>{searchTrim}</Text>
                    <Text className="primary">Invite contact</Text>
                  </Space>
                </Space>
              </Card>
            </div>
          ) : undefined
        }
        mode={mode}
        onChange={(value) => {
          const res = Array.isArray(value)
            ? data?.filter((el: any) => value.includes(el.value))
            : data?.find((el: any) => el.value === value);
          if (onChange) onChange(res);
        }}
        onSearch={(value) => {
          setSearch(value);
        }}
        onSelect={() => {
          setSearch('');
        }}
        placeholder={placeholder}
        searchValue={search}
        showArrow
        showSearch
        size={size as any}
        style={style}
      >
        {data?.map((el: any, i: any) => (
          <Select.Option key={i} label={el.label} value={el.value}>
            <Text strong={el.bold}>{render ? render(el) : el.label}</Text>
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default InviteContactSelectSearch;
