import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  data: null,
  error: null,
};

export const contentSlice = createSlice({
  name: 'content',
  initialState,
  reducers: {
    getContent: () => {},
    setContentLoading: (state) => ({ ...state, loading: true }),
    setContentData: (state, action) => ({ ...state, loading: false, data: action.payload }),
    setContentError: (state, action) => ({ ...state, loading: false, error: action.payload }),
  },
});

export const { setContentData, setContentError, setContentLoading, getContent } = contentSlice.actions;

export default contentSlice.reducer;
