/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { message } from 'antd';
import apiRoutes from 'config/apiRoute';
import exportFromJSON from 'export-from-json';
import apiRequests from 'utils/api';
import asyncErrorHandler from 'utils/asyncErrorHandler';
import useStickyState from 'utils/useStickyState';
import { convertSnakeCaseToTitleCase } from 'utils/string.utils';

import useColumns from './hooks/useColumns';

interface CustomTableProps {
  dispatchFunction: any;
  columns: any[];
  data: any;
  exportAllApi: any;
  tableKey: string;
  pagination: any;
  defaultSorter: any;
  customTrigger: any;
  defaultFilter: any;
  defaultParams: any;
  exportDefaultParams?: any;
  unmountDispatch: any;
  searchField?: string;
  searchArray?: boolean;
  editable: boolean;
  onRowUpdate: any;
  renderParams: any;
  onRowSelection: any;
  onFiltersChanged?: (params: any) => void;
}

const PAGE_SIZE = 50;
const CURRENT = 1;

const exportRenderThemeValues: any = {
  status: (value: any) => {
    return convertSnakeCaseToTitleCase(value);
  },
  users: (value: any) => {
    return `${value?.map((el: any, i: any) => `${el?.name}${i === value?.length - 1 ? '' : ', '}`)}`;
  },
  customer: (value: any) => {
    return value?.business_name;
  },
  customers: (values: any) => {
    let parsedVal = '';

    values?.forEach((el: any, i: any) => {
      parsedVal += `${el?.business_name}${values.length === i + 1 ? '' : ',  '}`;
    });

    return parsedVal;
  },
  array: (value: any) => {
    return value?.join(', ');
  },
  titleCaseArray: (value: any) => {
    return value?.map((el: any) => convertSnakeCaseToTitleCase(el))?.join(', ');
  },
};

const useCustomTable = (props: CustomTableProps) => {
  const {
    dispatchFunction,
    columns,
    data,
    exportAllApi,
    tableKey,
    pagination,
    defaultSorter,
    customTrigger,
    defaultFilter,
    defaultParams = {},
    exportDefaultParams = {},
    searchField,
    searchArray = false,
    unmountDispatch,
    editable,
    onRowUpdate,
    renderParams,
    onRowSelection,
    onFiltersChanged,
  } = props;

  const [state, setState] = useState({
    exportModal: false,
    exportModalFormat: null as any,
    columns,
    exporting: false,
    bulkLoading: false,
    bulkModal: false,
    trigger: 0,
    selectAllRows: false,
    selectAllCurrentRows: false,
    selectedRowKeys: [] as any,
  });

  const [stickyState, setStickyState] = useStickyState(
    {
      search: null as any,
      sorter: defaultSorter,
      pageSize: PAGE_SIZE,
      filter: defaultFilter,
      current: CURRENT,
    },
    `--temp-refresh-${tableKey}`,
  );

  const { trigger, selectedRowKeys, selectAllRows } = state;
  const { search, sorter, pageSize, current, filter } = stickyState;
  const dispatch = useDispatch();

  const filterBody = {} as any;
  if (data && data[0]?.uuid) {
    filterBody.uuid = selectedRowKeys;
  } else {
    filterBody.id = selectedRowKeys;
  }

  const triggerEffect = () => {
    setState((prevState) => ({ ...prevState, trigger: trigger + 1 }));
  };

  const getParams = (withPageParams = true) => {
    const checkParams = () => {
      let params: any = {};

      if (withPageParams) {
        params['page[size]'] = pageSize;
        params['page[number]'] = current;
      }

      if (filter?.filterBy && filter?.value) {
        params[`filters[${filter?.filterBy}][]`] = filter?.value;
      }

      if (Array.isArray(filter)) {
        filter.forEach((el) => {
          const currentFilters = params[`filters[${el.filterBy}]`];
          params[`filters[${el.filterBy}]`] = currentFilters ? [...currentFilters, el.value] : [el.value];
        });
      }

      if (sorter) {
        const pageSorting: any = {};
        pageSorting['sort[by]'] = sorter.column;
        pageSorting['sort[direction]'] = sorter.order === 'descend' ? 'desc' : 'asc';
        params = { ...params, ...pageSorting };
      }

      if (search) {
        if (searchField) {
          let prefix = 'search_term[0]';
          prefix += searchArray ? '[0]' : '';

          params[`${prefix}[field]`] = searchField;
          params[`${prefix}[rule]`] = 'contains';
          params[`${prefix}[value]`] = search;
        } else {
          params.search_term = search;
        }
      }

      return { ...params, ...defaultParams };
    };

    return renderParams ? renderParams(checkParams()) : checkParams();
  };

  useEffect(
    () => () => {
      if (unmountDispatch) dispatch(unmountDispatch());
    },
    [],
  );

  useEffect(() => {
    if (pagination?.current > pagination?.last) {
      setStickyState((prevState: any) => ({ ...prevState, current: 1 }));
    }
  }, [pagination?.current, pagination?.last]);

  useEffect(() => {
    if (selectAllRows && data) {
      const uuids = [] as any[];
      data?.forEach((el: any) => {
        uuids.push(el?.uuid);
        if (el?.children) {
          el?.children?.forEach((c: any) => {
            uuids?.push(c?.uuid);
          });
        }
      });

      if (data[0]?.uuid) {
        setState((prevState: any) => ({ ...prevState, selectedRowKeys: uuids }));
      } else {
        setState((prevState: any) => ({ ...prevState, selectedRowKeys: [...data.map((el: any) => el.id)] }));
      }
    }
  }, [selectAllRows, data]);

  useEffect(() => {
    const params = getParams(true);

    if (dispatchFunction) {
      dispatch(dispatchFunction(params));
    }

    onFiltersChanged?.(params);
  }, [dispatch, dispatchFunction, pageSize, current, sorter, search, searchField, trigger, customTrigger, filter]);

  const handleTableChange = (_pagination: any, filters: any, sort: any) => {
    setStickyState((prevState: any) => ({
      ...prevState,
      sorter: sort?.order
        ? {
            column: sort?.column?.sorterKey ?? sort?.column?.dataIndex,
            order: sort?.order,
          }
        : null,
    }));
  };

  const handleClearFilters = () => {
    setStickyState((prevState: any) => ({ ...prevState, filter: null }));
  };

  const fetchFullData = async (params: any = {}) => {
    try {
      const res = await apiRequests.get(exportAllApi, {
        'page[size]': 100000,
        'page[number]': 1,
        ...params,
      });

      return res.data.data;
    } catch (error) {
      asyncErrorHandler(error);
      throw error;
    }
  };

  const getExportData = async (fullExport: boolean) => {
    let exportData = null;

    if (selectAllRows || fullExport) {
      try {
        const params = getParams(false);
        exportData = await fetchFullData({ ...params, ...exportDefaultParams });
      } catch (error) {
        //
      }
    } else {
      exportData = data.filter((el: any) => !!selectedRowKeys.find((k: any) => el.uuid === k));
    }

    if (exportData) {
      return exportData.map((el: any) => {
        const newData: any = {};

        columns.forEach((col) => {
          if (!col.dataIndex || col.hideFromExport) {
            return;
          }

          const dataKey = col.customExportKey ?? col.dataIndex;

          const elValue = Array.isArray(dataKey) ? dataKey.reduce((prev, key) => prev?.[key], el) : el[dataKey];

          const dataValue = col.exportRenderTheme
            ? exportRenderThemeValues[col.exportRenderTheme](elValue)
            : col.exportRender === true && col.render
            ? col.render(elValue, el)
            : col.exportRender
            ? col.exportRender(el)
            : elValue;

          newData[col.title ?? dataKey] = dataValue ?? '';
        });

        return newData;
      });
    }

    return null;
  };

  const handleParseToCSV = async (fullExport: boolean) => {
    setState((prevState) => ({ ...prevState, exporting: true }));

    try {
      const exportData = await getExportData(fullExport);

      if (exportData) {
        exportFromJSON({ data: exportData, fileName: 'data', exportType: exportFromJSON.types.csv, delimiter: ';' });
      }
    } catch (error) {
      message.error('Something went wrong!');
      throw error;
    } finally {
      setState((prevState) => ({ ...prevState, exporting: false, exportModal: false }));
    }
  };

  const handleParseToXLSL = async (fullExport: boolean) => {
    setState((prevState) => ({ ...prevState, exporting: true }));

    try {
      const exportData = await getExportData(fullExport);

      if (exportData) {
        exportFromJSON({ data: exportData, fileName: 'data', exportType: exportFromJSON.types.xls });
      }
    } catch (error) {
      message.error('Something went wrong!');
      throw error;
    } finally {
      setState((prevState) => ({ ...prevState, exporting: false, exportModal: false }));
    }
  };

  const handleSetFilter = (filterData: any) => {
    setStickyState((prevState: any) => ({ ...prevState, filter: filterData }));
  };

  const handleClearFiltersAndSearch = () => {
    setStickyState((prevState: any) => ({ ...prevState, search: null, sorter: null, filter: null }));
  };

  const handleSearch = (e: any) => {
    setStickyState((prevState: any) => ({ ...prevState, search: e, current: CURRENT }));
    if (stickyState?.search === e) {
      triggerEffect();
    }
  };

  const toggleExportModal = () => {
    setState((prevState) => ({ ...prevState, exportModal: !prevState.exportModal }));
  };

  const onSelectChange = (rows: any) => {
    if (onRowSelection) {
      onRowSelection(rows, false);
    }
    setState((prevState: any) => ({ ...prevState, selectedRowKeys: rows, selectAllRows: false }));
  };

  const onSelectAll = (selected: any) => {
    if (onRowSelection) {
      onRowSelection(
        data.map((el: any) => el?.uuid),
        true,
      );
    }
    setState((prevState: any) => ({ ...prevState, selectAllRows: selected }));
  };

  const onPageSelectAll = (selected: any) => {
    const fieldKey = data[0]?.uuid ? 'uuid' : 'id';

    const selectedData = data.map((el: any) => el?.[fieldKey]);

    setState((prevState: any) => ({
      ...prevState,
      selectedRowKeys: selected ? selectedData : [],
      selectAllCurrentRows: selected,
      selectAllRows: false,
    }));
  };

  const handleStatus = async (value: 'active' | 'inactive') => {
    try {
      setState((prevState) => ({ ...prevState, bulkLoading: true }));
      if (selectAllRows) {
        await apiRequests.post(`${exportAllApi}/bulk-operations/update`, {
          filters: {
            _all: true,
          },
          data: {
            status: value,
          },
        });
      } else {
        await apiRequests.post(`${exportAllApi}/bulk-operations/update`, {
          filters: filterBody,
          data: {
            status: value,
          },
        });
      }
      triggerEffect();
      message.success('Updated the changes.');
      setState((prevState) => ({ ...prevState, bulkLoading: false }));
    } catch (error) {
      asyncErrorHandler(error);
      setState((prevState) => ({ ...prevState, bulkLoading: false }));
    }
  };

  const handleDeleteBulk = async () => {
    try {
      setState((prevState) => ({ ...prevState, bulkLoading: true }));
      if (selectAllRows) {
        await apiRequests.post(`${exportAllApi}/bulk-operations/delete`, {
          filters: {
            _all: true,
          },
        });
        setState((prevState: any) => ({ ...prevState, selectedRowKeys: [], selectAllRows: false }));
      } else {
        await apiRequests.post(`${exportAllApi}/bulk-operations/delete`, {
          filters: filterBody,
        });
        setState((prevState: any) => ({ ...prevState, selectedRowKeys: [] }));
      }
      triggerEffect();
      message.success('Updated the changes.');
      setState((prevState) => ({ ...prevState, bulkLoading: false, bulkModal: false }));
    } catch (error) {
      asyncErrorHandler(error);
      setState((prevState) => ({ ...prevState, bulkLoading: false }));
    }
  };

  const handleBulkExport = async () => {
    try {
      setState((prevState) => ({ ...prevState, bulkLoading: true }));
      if (selectAllRows) {
        await apiRequests.post(`${apiRoutes.USERS}/bulk-operations/export`, {
          filters: {
            _all: true,
          },
        });
      } else {
        await apiRequests.post(`${apiRoutes.USERS}/bulk-operations/export`, {
          filters: filterBody,
        });
      }
      message.success('Exported successfully.');
      setState((prevState) => ({ ...prevState, bulkLoading: false }));
    } catch (error) {
      asyncErrorHandler(error);
      setState((prevState) => ({ ...prevState, bulkLoading: false }));
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    onSelectAll: onPageSelectAll,
  };

  const updatedColumns = useColumns(columns, sorter, editable, onRowUpdate);
  return {
    state,
    stickyState,
    updatedColumns,
    rowSelection,
    onSelectAll,
    handleSearch,
    handleClearFilters,
    handleClearFiltersAndSearch,
    handleDeleteBulk,
    handleBulkExport,
    handleStatus,
    handleTableChange,
    toggleExportModal,
    handleParseToXLSL,
    handleParseToCSV,
    handleSetFilter,
    setState,
    triggerEffect,
    setStickyState,
  };
};

export default useCustomTable;
