import { useEffect, useState } from 'react';
import apiRoutes from 'config/apiRoute';
import apiRequests from 'utils/api';
import asyncErrorHandler from 'utils/asyncErrorHandler';
import useWebSocket from 'utils/useWebSocket';

const useTaskActivity = (uuid: string | null) => {
  const [state, setState] = useState({ loading: false, data: [] as any, hasMore: false });

  const fetchData = async (_uuid: string, reset = false, abort?: AbortController) => {
    const getParams = () => {
      if (state.data.length === 0 || reset) {
        return {};
      }

      return {
        before: state.data.at(-1).created_at,
      };
    };

    setState((prevState) => ({ ...prevState, loading: true }));

    try {
      const res = await apiRequests.get(`${apiRoutes.PROJECT_TASKS}/${_uuid}/logs`, getParams(), {}, abort);

      const resData = res.data.data;

      setState((prevState) => ({
        ...prevState,
        loading: false,
        data: [...(reset ? [] : prevState.data.filter((item: any) => !resData.find((x: any) => x.id === item.id))), ...resData],
        hasMore: resData.length > 0 && resData.at(-1).action !== 'create',
      }));
    } catch (error) {
      if (error.code === 'ERR_CANCELED') return;
      setState((prevState) => ({ ...prevState, loading: false }));
      asyncErrorHandler(error);
    }
  };

  useEffect(() => {
    const abort = new AbortController();

    if (uuid) {
      fetchData(uuid, true, abort);
    }

    return () => {
      abort.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid]);

  useWebSocket({
    channelName: 'task.move',
    listen: {
      event: '.App\\Events\\Projects\\TaskMoveEvent',
      callback: async (event: any) => {
        if (event.action !== 'update' || !uuid || event.uuid !== uuid || state.loading) return;

        setState((prev) => ({ ...prev, data: [] }));
        fetchData(uuid, true);
      },
    },
  });

  return {
    state,
    setState,
    fetchData,
  };
};

export default useTaskActivity;
