import { AxiosResponse } from 'axios';
import apiRoutes from 'config/apiRoute';
import { call, put, StrictEffect, takeEvery } from 'redux-saga/effects';
import api from 'utils/api';
import asyncErrorHandler from 'utils/asyncErrorHandler';

import {
  getDashboardProjects,
  setDashboardProjectsData,
  setDashboardProjectsError,
  setDashboardProjectsLoading,
} from './dashboardProjects-slice';

const { get } = api;

function* getDashboardProjectsSagaListener(action: any) {
  try {
    yield put(setDashboardProjectsLoading());
    const res: AxiosResponse = yield call(get, apiRoutes.PROJECTS_HISTORY, action.payload);
    yield put(setDashboardProjectsData(res.data.data));
  } catch (error) {
    yield put(setDashboardProjectsError(error));
    asyncErrorHandler(error);
  }
}

function* dashboardProjectsSaga(): Generator<StrictEffect> {
  yield takeEvery(getDashboardProjects.type, getDashboardProjectsSagaListener);
}

export default dashboardProjectsSaga;
