import { FC, useEffect, useState } from 'react';
import { Select, Form } from 'antd';
import { TbTag } from 'react-icons/tb';
import asyncErrorHandler from 'utils/asyncErrorHandler';
import apiRequests from 'utils/api';
import apiRoutes from 'config/apiRoute';

interface TaskTagSelectProps {}

const TaskTagSelect: FC<TaskTagSelectProps> = () => {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const abort = new AbortController();

    const fetchTags = async () => {
      setLoading(true);

      try {
        const { data } = await apiRequests.get(
          apiRoutes.TAGS,
          {
            'page[size]': 500,
          },
          {},
          abort,
        );

        setOptions(
          data.data.map((x: any) => ({
            value: x.name,
            label: x.name,
          })),
        );
        setLoading(false);
      } catch (error) {
        if (error.code === 'ERR_CANCELED') return;
        asyncErrorHandler(error);
        setLoading(false);
      }
    };

    fetchTags();

    return () => {
      abort.abort();
    };
  }, []);

  return (
    <Form.Item
      label={
        <>
          <TbTag />
          Tags
        </>
      }
      name="tags"
      className="mb-0 form-item-horizontal"
    >
      <Select
        placeholder="Select or insert a tag"
        mode="tags"
        size="middle"
        disabled={loading}
        loading={loading}
        options={options}
        showSearch
      />
    </Form.Item>
  );
};

export default TaskTagSelect;
