import { AxiosResponse } from 'axios';
import apiRoutes from 'config/apiRoute';
import { call, put, StrictEffect, takeEvery } from 'redux-saga/effects';
import api from 'utils/api';
import asyncErrorHandler from 'utils/asyncErrorHandler';

import { getClearContacts, getContacts, setContactsData, setContactsError, setContactsLoading } from './contacts-slice';

const { get } = api;

function* getContactsSagaListener(action: any) {
  try {
    yield put(setContactsLoading());
    const res: AxiosResponse = yield call(get, apiRoutes.CONTACTS, action.payload);
    yield put(setContactsData(res.data));
  } catch (error) {
    yield put(setContactsError(error));
    asyncErrorHandler(error);
  }
}

function* contactsSaga(): Generator<StrictEffect> {
  yield takeEvery(getContacts.type, getContactsSagaListener);
  yield takeEvery(getClearContacts.type, getContactsSagaListener);
}

export default contactsSaga;
