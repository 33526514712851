import { FC } from 'react';
import SimpleBar from 'simplebar-react';
import { useSelector } from 'react-redux';
import { Layout, Menu } from 'antd';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import { checkIsSuperUser } from 'config/permissions';
import { getRouteConfig, MenuItemBaseLinkType } from './config';
import MenuItem from './MenuItem';
import styles from './index.module.less';

const { Sider } = Layout;

const version = process.env.REACT_APP_GIT_HASH ? process.env.REACT_APP_GIT_HASH.substring(0, 6) : null;

interface SidebarProps {
  activeRoute?: MenuItemBaseLinkType;
}

const Sidebar: FC<SidebarProps> = ({ activeRoute }) => {
  const user = useSelector((store: any) => store.auth.user);

  const breakpoints = useBreakpoint();

  if (!breakpoints.sm) {
    return null;
  }

  const isSuperUser = checkIsSuperUser(user?.email);
  const config = getRouteConfig();

  return (
    <Sider collapsedWidth="0" theme="light" trigger={null} className={styles.sidebar}>
      <SimpleBar className={styles.simpleBar}>
        <Menu mode="inline" theme="light" selectable={false}>
          {config.map((item) => (
            <MenuItem
              key={`${'path' in item ? item.path : item.key}`}
              item={item}
              user={user}
              selectedItem={activeRoute && 'path' in activeRoute ? activeRoute : undefined}
              classNameLabel={styles.itemLabel}
              isSuperUser={isSuperUser}
            />
          ))}
        </Menu>
      </SimpleBar>

      <div className={styles.version}>{version ? `Ver: ${version}` : undefined}</div>
    </Sider>
  );
};

export default Sidebar;
