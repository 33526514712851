import { createSlice } from '@reduxjs/toolkit';

export const emailLogsSlice = createSlice({
  name: 'emailLogs',
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {
    getEmailLogs: () => {},
    setEmailLogsLoading: (state) => ({ ...state, loading: true }),
    setEmailLogsData: (state, action) => ({ ...state, loading: false, data: action.payload }),
    setEmailLogsError: (state, action) => ({ ...state, loading: false, error: action.payload }),
  },
});

export const { setEmailLogsData, setEmailLogsError, setEmailLogsLoading, getEmailLogs } = emailLogsSlice.actions;

export default emailLogsSlice.reducer;
