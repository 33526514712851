/* eslint-disable no-nested-ternary */
import { useEffect, useRef, useState } from 'react';
import { Channel } from 'laravel-echo';
import Echo from '../websocket';

interface EventListen {
  event: string;
  callback: (event: any) => void;
}

interface UseWebSocketParams {
  channelName: string;
  privateChannel?: boolean;
  notification?: (event: any) => void;
  listen?: EventListen | EventListen[];
}

export default function useWebSocket({ channelName, privateChannel = true, notification, listen }: UseWebSocketParams) {
  const listenList = Array.isArray(listen) ? listen : listen ? [listen] : undefined;

  const [notificationChannel, setNotificationChannel] = useState<Channel>();
  const listenRef = useRef(listenList);
  const notificationRef = useRef(notification);

  listenRef.current = listenList;
  notificationRef.current = notification;

  useEffect(() => {
    const channel = privateChannel ? Echo.private(channelName) : Echo.channel(channelName);

    setNotificationChannel(channel);

    return () => {
      Echo.leaveChannel(channelName);
    };
  }, [privateChannel, channelName]);

  useEffect(() => {
    if (!notificationChannel) {
      return () => {};
    }

    let notificationCb: (event: any) => void | undefined;

    if (notificationRef.current) {
      notificationCb = (event: any) => {
        notificationRef.current?.(event);
      };

      notificationChannel.notification(notificationCb);
    }

    let listenWrapper: EventListen[] | undefined;

    if (listenRef.current) {
      listenWrapper = listenRef.current.map((lis, index) => {
        const callback = (event: any) => {
          listenRef.current?.[index]?.callback?.(event);
        };

        notificationChannel.listen(lis.event, callback);

        return {
          event: lis.event,
          callback,
        };
      });
    }

    return () => {
      if (notificationCb) {
        notificationChannel.stopListening('.Illuminate\\Notifications\\Events\\BroadcastNotificationCreated', notificationCb);
      }

      if (listenWrapper) {
        listenWrapper.forEach((lis) => {
          notificationChannel.stopListening(lis.event, lis.callback);
        });
      }
    };
  }, [notificationChannel]);
}
