import { FC } from 'react';
import { Button, Space } from 'antd';
import { CloudDownloadOutlined, ReloadOutlined } from '@ant-design/icons';

interface ActionButtonsProps {
  loadingReloadButton?: boolean;
  showReloadButton?: boolean;
  onReload: any;
  showExportButton?: boolean;
  loadingExportButton?: boolean;
  onExport: any;
}

const ActionButtons: FC<ActionButtonsProps> = ({
  showExportButton = true,
  showReloadButton = true,
  loadingExportButton = false,
  loadingReloadButton = false,
  onReload,
  onExport,
}) => {
  return (
    <Space>
      {showReloadButton && (
        <Button disabled={loadingReloadButton} icon={<ReloadOutlined />} onClick={onReload} size="large" type="default" />
      )}

      {showExportButton && (
        <Button
          disabled={loadingExportButton}
          icon={<CloudDownloadOutlined style={{ fontSize: 25 }} />}
          onClick={onExport}
          size="large"
          type="default"
        />
      )}
    </Space>
  );
};

export default ActionButtons;
